import moment from 'moment/moment';
import { A5500_DEFAULT_CONFIG } from '../../utils/device-config';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('programsChildControllerA55', programsChildControllerA55);

  programsChildControllerA55.$inject = [
    '$log',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'progFactory',
    'unitFactory',
    'UserData',
    'registerFactory',
    'resFactory',
    'NgTableParams',
    '$filter',
    'fertilizerFactory',
    '$rootScope',
    '$confirm',
    'manualFactory',
    'sensorsFactory',
  ];

  function programsChildControllerA55(
    $log,
    $scope,
    $state,
    Restangular,
    $mdDialog,
    progFactory,
    unitFactory,
    UserData,
    registerFactory,
    resFactory,
    NgTableParams,
    $filter,
    fertilizerFactory,
    $rootScope,
    $confirm,
    manualFactory,
    sensorsFactory
  ) {
    var vm = this;
    vm.activeList;
    vm.getSubprogramStartTime;
    vm.formatCE;

    var temporal_register;
    var editing = false;
    activate();

    function activate() {
      vm.viewDate = moment().format();

      vm.currentState = $state.includes;

      if ($state.params.program !== null) {
        vm.currentUnit = $state.params.unit;

        vm.program = $state.params.program;
        vm.validateActiveProgramFert = validateActiveProgramFert;
        //vm.program = progFactory.formatProgramView(vm.program);
        vm.getTimeFromMins = progFactory.getTimeFromMins;
        vm.parseTimeFromMin = getTimeFromMin;
        vm.toDate = toDate;
        vm.toHour = toHour;
        vm.formatDate = formatDate;
        vm.selectedOp = '1';
        vm.getValueInUnit = getValueInUnit;
        /*           vm.getCurrentFertilizer = getCurrentFertilizer;
           vm.formatFertilizerValue = formatFertilizerValue; */
        vm.getFertF5Text55 = getFertF5Text55;
        vm.formatFert55 = formatFert55;
        vm.fertilizers = [];
        vm.cancel = cancel_edition;
        vm.save = save;
        vm.manualStart = manualStart;
        vm.manualStop = manualStop;
        vm.manualOutOf = manualOutOf;
        vm.manualRunOf = manualRunOf;
        vm.manualSuspend = manualSuspend;

        vm.unitValues = A5500_DEFAULT_CONFIG.measurementUnits.irrigation;
        vm.irrigationMeasureUnit = vm.unitValues.find(
          (item) => item.deviceMeasurementUnitKey === vm.program.unit
        ).measurementUnit;
        switch ($state.current.name) {
          case 'a55programs.detail':
            loadRegisterData();
            loadFertilizerParameters();
            loadConditioners();
            prepareSectorData();
            vm.goToSector = goToSector;
            vm.viewChangeClicked = viewChangeClicked;
            break;
          case 'a55programs.edit':
            // if(!vm.program.backup){
            backup();
            // }
            editing = true;
            prepareFormatCE();
            prepareSectorData();
            prepareFertDecimals();
            prepareIrrigationValues(true);
            vm.programsList = _.range(0, 17);
            vm.activeHour = vm.currentUnit.installer.activeSchedule;
            vm.activePeriod = vm.currentUnit.installer.activePeriod;
            break;
          case 'a55programs.config':
            // if(!vm.program.backup){
            backup();
            // }
            unitFactory.conditioners(vm.currentUnit.id).then((data) => {
              vm.currentUnit.conditioners = data.plain();
            });
            break;
        }

        vm.priorValues = _.range(0, 100);

        /*vm.events = progFactory.getEventTable(vm.program,moment().format("MM-YYYY"));*/
      } else {
        $state.go('units');
      }
    }

    function prepareFormatCE() {
      sensorsFactory.getSensor(vm.currentUnit.id, 0, vm.currentUnit.fertilizer.checkCE).then((resp) => {
        var sensorCE = resp.plain();
        sensorsFactory.getFormat(vm.currentUnit.id, sensorCE.formatId).then((r) => {
          vm.formatCE = r.plain();
        });
      });
    }
    function prepareSectorData() {
      if (vm.program.irrigation) {
        var start = _.findIndex(vm.program.programSector, { xState: 1 });
        _.forEach(vm.program.programSector, (p, k) => {
          if (k < start) {
            p.pending = p.value;
            p.process = 100;
          } else if (p.sector > 0) {
            var temp_sector = vm.currentUnit.sectors[p.sector - 1];
            if (temp_sector !== undefined) {
              if (vm.program.unit === 2) {
                let real_value = p.value * (temp_sector.area / 10000); // m3/Ha -> m3
                p.real_value = real_value;
                p.pending = real_value - temp_sector.xValue;
                p.process = ((real_value - temp_sector.xValue) / real_value) * 100;
              } else {
                p.pending = p.value - temp_sector.xValue;
                p.process = ((p.value - temp_sector.xValue) / p.value) * 100;
              }
            }
          }
        });
      }
    }

    function prepareIrrigationValues(input) {
      switch (vm.program.unit) {
        case 2:
        case 1: {
          if (input) {
            vm.program.preIrrigation = vm.program.preIrrigation / 1000;
            vm.program.postIrrigation = vm.program.postIrrigation / 1000;
            _.forEach(vm.program.programSector, (p) => {
              p.value = p.value / 1000;
            });
          } else {
            vm.program.preIrrigation = vm.program.preIrrigation * 1000;
            vm.program.postIrrigation = vm.program.postIrrigation * 1000;
            _.forEach(vm.program.programSector, (p) => {
              p.value = p.value * 1000;
            });
          }
        }
      }
    }

    function toDate(date) {
      return moment(date, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY');
    }

    function toHour(date) {
      return moment(date, 'DD-MM-YYYY HH:mm').format('HH:mm');
    }

    function goToSector(id) {
      $state.go('sectors', { unit: vm.currentUnit, load: id });
    }

    function getStartTime() {
      var total = 0;
      var parts = vm.program.irrigationHour.split(':');
      for (var i = 0; i < index; i++) {
        total = total + vm.activeSubList[i].totalTime;
      }
      var hoursToMin = (parseInt(parts[0]) * 60) | 0;
      var temp = hoursToMin + parseInt(parts[1]) + total;
      if (temp > 1440) {
        temp = temp - 1440;
      }
      var irrigationHour = progFactory.getTimeFromMins(temp);
      return new Date(irrigationHour._d);
    }

    function getTimeFromMin(min) {
      if (_.isNumber(min)) {
        return progFactory.getTimeFromMins(min).format('HH:mm');
      }
      return min;
    }
    function viewChangeClicked(nextView) {
      if (nextView === 'year' || nextView === 'day' || nextView === 'week') {
        return false;
      }
    }

    function validateActiveProgramFert() {
      return vm.currentUnit.fertilizer.xNumProgram === Number(vm.program.pk.id);
    }

    function successRegister(data) {
      var register = data.plain();

      if (register.content !== undefined && register.content.length > 0) {
        registerFactory.parseRegister('6', register.content);
        var response = register.content;
        vm.tableParams.total(register.totalElements);
        return response;
      }
    }

    function loadConditioners() {
      unitFactory.conditioners(vm.currentUnit.id).then((response) => {
        vm.currentUnit.conditioners = response.plain();
        var conditioners = [];
        if (vm.program.condId1 !== 0) {
          var cond = vm.currentUnit.conditioners[vm.program.condId1 - 1];
          if (cond !== undefined) {
            conditioners.push(cond);
          }
        }
        if (vm.program.condId2 !== 0) {
          var cond = vm.currentUnit.conditioners[vm.program.condId2 - 1];
          if (cond !== undefined) {
            conditioners.push(cond);
          }
        }
        if (vm.program.condId3 !== 0) {
          var cond = vm.currentUnit.conditioners[vm.program.condId3 - 1];
          if (cond !== undefined) {
            conditioners.push(cond);
          }
        }
        if (vm.program.condId4 !== 0) {
          var cond = vm.currentUnit.conditioners[vm.program.condId4 - 1];
          if (cond !== undefined) {
            conditioners.push(cond);
          }
        }
        if (vm.program.condId5 !== 0) {
          var cond = vm.currentUnit.conditioners[vm.program.condId5 - 1];
          if (cond !== undefined) {
            conditioners.push(cond);
          }
        }
        vm.conditionerList = conditioners;
      });
    }

    function loadRegisterData() {
      vm.tableParams = new NgTableParams(
        {
          page: 1,
          count: 15,
          filter: { type: '' },
        },
        {
          counts: [],
          getData: function (params) {
            var pageNumber = 1;
            if (params._params) {
              pageNumber = params._params.page;
            }

            if (params._params.filter.type !== '') {
              var type = params._params.filter.type;
              return unitFactory
                .programRegisters(vm.program.pk.deviceId, vm.program.pk.id, type, pageNumber)
                .then(successRegister);
            } else {
              return unitFactory
                .programRegisters(vm.program.pk.deviceId, vm.program.pk.id, null, pageNumber)
                .then(successRegister);
            }
          },
        }
      );
    }

    function getValueInUnit(input) {
      if (_.isNumber(input)) {
        var unit = vm.program.unit;
        if (unit === 0 || unit === 16) {
          input = input / 60;
        }
        switch (unit) {
          case 0:
            return $filter('parsetime')(input * 60, true);
            break;
          case 1:
            return input / 1000 + 'm3';
            break;
          case 3:
            var mins = input;
            return $filter('parsetime')(mins, true);
            break;
          case 4:
            return input + 'l';
            break;
          case 2:
            return input / 1000 + 'm3';
            break;
        }
      } else {
        return '-';
      }
    }

    function getFertF5Text55(fert) {
      switch (fert.xState) {
        case 0:
          return '-';
          break;
        case 1:
          return $filter('translate')('filters.wait');
          break;
        case 2:
          return vm.formatFert55(fert.xVolume, true);
          break;
        case 3:
          return $filter('translate')('general.pause');
          break;
      }
    }

    function formatFert55(val, f5) {
      var format = vm.currentUnit.fertilizer.fertUnits;
      var decimals = vm.currentUnit.fertilizer.fertDecimals;
      if (f5) {
        format = vm.currentUnit.fertilizer.fertUnitsF5;
        decimals = vm.currentUnit.fertilizer.fertDecimalsF5;
      }
      switch (format) {
        case 0: // hh:mm
          return $filter('parsetime')(val, true);
          break;
        case 1: // mm' ss"
          var m = (val / 60) | 0,
            s = val % 60 | 0;
          return m + '" ' + s + "'";
          break;
        case 2: // l
          return (val / 100).toFixed(decimals) + ' l';
        case 3: // l/Ha
          return (val / 100).toFixed(decimals) + ' l/Ha';
      }
    }

    function formatDate(date) {
      return moment(date).format('MMMM-YYYY');
    }

    function prepareFertDecimals() {
      vm.fertUnits = vm.currentUnit.fertilizer.fertUnits;

      vm.unitFertValues = A5500_DEFAULT_CONFIG.measurementUnits.fertilization;

      vm.fertilizerUnit = vm.unitFertValues.find(
        (item) => item.deviceMeasurementUnitKey === vm.fertUnits
      ).measurementUnit;

      vm.fertilizerUnitF5 = vm.unitFertValues.find(
        (item) => item.deviceMeasurementUnitKey === vm.currentUnit.fertilizer.fertUnitsF5
      ).measurementUnit;

      if (!vm.program.fertloaded && vm.program.fertType > 0) {
        switch (vm.fertUnits) {
          case 2:
          case 3:
            vm.program.fertilizer1 = vm.program.fertilizer1 / 100;
            vm.program.fertilizer2 = vm.program.fertilizer2 / 100;
            vm.program.fertilizer3 = vm.program.fertilizer3 / 100;
            vm.program.fertilizer4 = vm.program.fertilizer4 / 100;
            break;
        }
      }
      if (!vm.program.fertloaded) {
        switch (vm.currentUnit.fertilizer.fertUnitsF5) {
          case 2:
          case 3:
            vm.program.fertilizer5 = vm.program.fertilizer5 / 100;
            break;
        }
      }
      vm.program.fertloaded = true;
    }

    function getCurrentFertilizer(index) {
      return vm.fertilizers[index];
    }

    function loadFertilizerParameters() {
      fertilizerFactory.all(vm.currentUnit.id).then((fert) => {
        vm.fertilizers = fert.fertilizers;
      });
    }

    function prepareFertilizationValues() {
      vm.fertUnits = vm.currentUnit.fertilizer.fertUnits;
      if (vm.program.fertType > 0) {
        switch (vm.fertUnits) {
          case 2:
          case 3:
            vm.program.fertilizer1 = vm.program.fertilizer1 * 100;
            vm.program.fertilizer2 = vm.program.fertilizer2 * 100;
            vm.program.fertilizer3 = vm.program.fertilizer3 * 100;
            vm.program.fertilizer4 = vm.program.fertilizer4 * 100;
            break;
        }
      }
      switch (vm.currentUnit.fertilizer.fertUnitsF5) {
        case 2:
        case 3:
          vm.program.fertilizer5 = vm.program.fertilizer5 * 100;
          break;
      }
    }

    function backup() {
      vm.program_backup = {};
      angular.copy(vm.program, vm.program_backup);
      vm.program.backup = true;
    }

    function cancel_edition() {
      angular.copy(vm.program_backup, vm.program);
      $state.go('a55programs.detail', { unit: vm.currentUnit, program: vm.program_backup });
    }

    function save() {
      if (vm.form) vm.form.$setPristine();
      if (vm.formProgram) vm.formProgram.$setPristine();
      vm.program.progtype = vm.currentUnit.type;
      if (editing) {
        prepareIrrigationValues();
        prepareFertilizationValues();
      }
      progFactory.update(vm.program).then((response) => {
        //$state.go('programs.detail',{unit:vm.currentUnit,program:response.plain()},{reload:true});

        $rootScope.$broadcast('updateEdit55', { message: 'refreshing' });
      });
    }

    function manualStart() {
      $confirm({ text: 'Enviar inicio manual?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(function () {
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.program.pk.deviceId;
        params.action = 4;
        params.parameter1 = Number(vm.program.pk.id) - 1;
        manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
        var modalInstance = manualFactory.showLoadingBar();
        modalInstance.result.then((result) => {
          $state.go('a55programs', { unit: $state.params.unit }, { reload: 'a55programs' });
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function manualStop() {
      $confirm({ text: 'Quiere enviar un paro de programa?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(
        function () {
          var params = {};
          params.type = vm.currentUnit.type;
          params.deviceId = vm.program.pk.deviceId;
          params.action = 5;
          params.parameter1 = Number(vm.program.pk.id) - 1;
          manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
          var modalInstance = manualFactory.showLoadingBar();
          modalInstance.result.then((result) => {
            $state.go('a55programs', { unit: $state.params.unit }, { reload: 'a55programs' });
            $scope.$emit('reload', { message: 'reload' });
          });
        }
      );
    }

    function manualOutOf() {
      $confirm({ text: 'Enviar fuera de servicio?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(
        function () {
          var params = {};
          params.type = vm.currentUnit.type;
          params.deviceId = vm.program.pk.deviceId;
          params.action = 0;
          params.parameter1 = Number(vm.program.pk.id) - 1;
          params.parameter2 = 1;
          manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
          var modalInstance = manualFactory.showLoadingBar();
          modalInstance.result.then((result) => {
            $state.go('a55programs', { unit: $state.params.unit }, { reload: 'a55programs' });
            $scope.$emit('reload', { message: 'reload' });
          });
        }
      );
    }

    function manualRunOf() {
      $confirm({ text: 'Enviar orden de activacion?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(
        function () {
          var params = {};
          params.deviceId = vm.program.pk.deviceId;
          params.type = vm.currentUnit.type;
          params.action = 0;
          params.parameter1 = Number(vm.program.pk.id) - 1;
          params.parameter2 = 0;
          manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
          var modalInstance = manualFactory.showLoadingBar();
          modalInstance.result.then((result) => {
            $state.go('a55programs', { unit: $state.params.unit }, { reload: 'a55programs' });
            $scope.$emit('reload', { message: 'reload' });
          });
        }
      );
    }

    function manualSuspend() {
      var modalInstance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'components/programs/suspend-modal.html',
        controller: 'ModalInstanceSuspendCtrl',
        controllerAs: 'vm',
      });

      modalInstance.result.then(
        function (selectedItem) {
          var modalInstance = manualFactory.showLoadingBar();

          var params = {};
          params.type = vm.currentUnit.type;
          params.deviceId = vm.program.pk.deviceId;
          params.action = 1;
          params.parameter1 = Number(vm.program.pk.id) - 1;
          params.parameter2 = selectedItem;
          params.parameter3 = selectedItem >> 8;
          manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
          modalInstance.result.then((result) => {
            $rootScope.$broadcast('reload', { message: 'refreshing' });
          });
        },
        function () {
          $log.info('Modal dismissed at: ' + new Date());
        }
      );
    }

    /**
     * Capture save event from parent
     */
    $scope.$on('formSubmit', (e) => save(e));

    /**
     * Emit form state to the parent
     */
    $scope.$watch(
      () => vm.formProgram,
      () => $scope.$emit('formUpdated', vm.formProgram)
    );

    $scope.$watch(
      function () {
        return moment(vm.viewDate).format('MM-YYYY');
      },
      function (month) {
        vm.events = progFactory.getEventTable(vm.program, month);
      }
    );

    $scope.$watch(
      function () {
        return moment(vm.viewDate).format('MM-YYYY');
      },
      function (month) {
        vm.events = progFactory.getEventTable(vm.program, month);
      }
    );
  }
})();
