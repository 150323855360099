import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getContainer } from '@vegga/front-store';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('psepController', psepController);

  psepController.$inject = ['$scope', '$state', 'commFactory', 'PSEP', 'unitFactory'];

  function psepController($scope, $state, commFactory, PSEP, unitFactory) {
    var vm = this;
    var backup;

    vm.destroy$ = new Subject();
    vm.save = save;
    vm.cancel = cancel_edition;
    vm.open = open;
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    activate();

    function activate() {
      vm.currentState = $state.includes;

      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }

        vm.currentUnit = currentUnit;
        vm.psep = PSEP;
        backup = angular.copy(vm.psep);
      });
    }

    function save() {
      commFactory.updatePSEP(vm.currentUnit.id, vm.psep).then(
        (result) => {
          vm.psep = result;
          backup = angular.copy(vm.psep);
        },
        (error) => {}
      );
    }

    function cancel_edition() {
      vm.psep = angular.copy(backup);
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.form,
      () => {
        $scope.$emit('formUpdated', vm.form);
      }
    );

    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
