import { FERT_TYPE } from '../../utils/enums/programs/programs-fertilization-type.enum';
import { FERT_UNITS } from '../../utils/enums/programs/programs-fertilization-units.enum';
import { FERT_MODE } from '../../utils/enums/programs/programs-fertilization-mode.enum';
import { $q } from 'angular-ui-router';
import moment from 'moment/moment';
import { A4500_DEFAULT_CONFIG } from '../../utils/device-config';
import { getContainer } from '@vegga/front-store';
import { Subject, take, takeUntil } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('programsEditControllerA45', programsEditController);

  programsEditController.$inject = [
    '$log',
    '$scope',
    '$rootScope',
    '$state',
    'Restangular',
    '$mdDialog',
    'progFactory',
    'UserData',
    '$filter',
    '$mdSelect',
    'resFactory',
    'configFactory',
    'veggaSelectFactory',
    'fertilizerFactory',
    'sectorFactory',
    '$confirm',
  ];

  function programsEditController(
    $log,
    $scope,
    $rootScope,
    $state,
    Restangular,
    $mdDialog,
    progFactory,
    UserData,
    $filter,
    $mdSelect,
    resFactory,
    configFactory,
    veggaSelectFactory,
    fertilizerFactory,
    sectorFactory,
    $confirm
  ) {
    var vm = this;
    var loadedGeneral = false;
    var loadedLinear = false;
    var loadedFertilizer = false;
    var loadedSubprograms = false;

    // api queries data
    var allWaterMix;
    var programSectors;
    var programSectorsBackup;
    var subprogramsBackup;
    var allFitos;
    var subprograms;
    var fertilizationHeaders;

    vm.FERT_TYPE = FERT_TYPE;
    vm.FERT_UNITS = FERT_UNITS;
    vm.FERT_MODE = FERT_MODE;

    vm.checkdirty = checkdirty;
    vm.cancel = cancel_edition;
    vm.save = save;
    vm.changeNav = changeNav;
    vm.loadState = loadState;
    vm.getFertUnitsLabel = getFertUnitsLabel;
    vm.getFertTypeLabel = getFertTypeLabel;
    vm.getSectorOrSectorGroupName = getSectorOrSectorGroupName;
    vm.deleteRow = deleteRow;
    vm.insertRow = insertRow;
    vm.insertSubprogram = insertSubprogram;
    vm.toggleFormat = toggleFormat;
    vm.getIrrigationFormatSuffix = getIrrigationFormatSuffix;
    vm.getPreIrrigationFormatSuffix = getPreIrrigationFormatSuffix;
    vm.getPostIrrigationFormatSuffix = getPostIrrigationFormatSuffix;
    vm.checkWhichInputToShow = checkWhichInputToShow;
    vm.checkIfHoursOrMinutes = checkIfHoursOrMinutes;
    vm.getIntegers = getIntegers;
    vm.getDecimals = getDecimals;
    vm.getConversionFactor = getConversionFactor;
    vm.updateLinearHeader = updateLinearHeader;
    vm.updateFertValue = updateFertValue;
    vm.openSubProgramWindow = openSubProgramWindow;
    vm.cancelModule = cancelModule;
    vm.saveModule = saveModule;
    vm.deleteSubprogram = deleteSubprogram;
    vm.findFirstHeader = findFirstHeader;
    vm.updateSectors = updateSectors;
    vm.setProgramHeader = setProgramHeader;
    vm.setHeader = setHeader;
    vm.setSectorsDisabledIfNecessary = setSectorsDisabledIfNecessary;
    vm.updateSectorsState = updateSectorsState;
    vm.updateSubprogram = updateSubprogram;
    // vm.countLinearAgrupationSectors = countLinearAgrupationSectors;
    // vm.disableSectors = disableSectors;
    vm.restartAgroupation = restartAgroupation;
    vm.changeMaxDays = changeMaxDays;
    vm.showSecurityTime = showSecurityTime;
    vm.showSecurityTimeColumn = showSecurityTimeColumn;
    vm.showSecurityTimeSubprograms = showSecurityTimeSubprograms;
    vm.getIrrigMax = getIrrigMax;
    vm.getPreIrrigMax = getPreIrrigMax;
    vm.getPostIrrigMax = getPostIrrigMax;
    vm.updateFertilizerValues = updateFertilizerValues;
    vm.updateRangeDates = updateRangeDates;
    vm.toggleActivePeriod = toggleActivePeriod;

    vm.selectedNav = 'general';
    vm.indexSelectedNav = 0;

    vm.unitTypeValues = {
      0: $filter('translate')('calcs.units'),
      1: $filter('translate')('fert.uni'),
      2: $filter('translate')('fert.prop') + ' L/m3',
      3: $filter('translate')('fert.prop') + ' cl/L',
      4: $filter('translate')('fert.regulation') + ' CE',
      5: $filter('translate')('programs.config.inputce'),
    };
    vm.unitFertValues = A4500_DEFAULT_CONFIG.measurementUnits.fertilization;

    vm.form;
    vm.cancel;
    vm.save;
    vm.checkdirty;
    vm.formProgram;
    vm.autofocusElement;

    vm.fertValuesProportional = [];
    vm.fertIrrigProportional = [];
    vm.fertValues = [];
    vm.subProgramSelected = null;
    var subProgramSelectedCopy;
    var subProgramFertSelectedCopy;
    var sectorsCounter = {};
    vm.selectedSectorsList = [];
    vm.selectedSectorsIdList = [];
    vm.totalSelectedSectors = 0;
    vm.duplicatedSectors = false;

    vm.destroy$ = new Subject();

    vm.programsFacade = getContainer().resolve('programsFacade');
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    activate();

    function initSubscriptions() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        vm.FERT_TYPE = FERT_TYPE;
        vm.FERT_UNITS = FERT_UNITS;
        vm.FERT_MODE = FERT_MODE;
        vm.currentUnit = currentUnit;
        vm.programsFacade.getA4500Program(vm.currentUnit.id, $state.params.id);
      });

      vm.programsFacade.clearProgramResponse();
      vm.programsFacade.programById$.pipe(take(1)).subscribe((prog) => {
        const program = progFactory.formatProgramView(prog);
        vm.editProgram = progFactory.checkIrrigation(program, vm.currentUnit.type);
        loadData();
      });

      vm.programsFacade.programs$.pipe(take(1)).subscribe(progs => {
        vm.programsList = progs;
      });
    }

    function activate() {
      if (!$state.params.id) {
        $state.go('a45programs');
        return;
      }
      initSubscriptions();
      // vm.programHeader = $state.params.program.xHeader;
    }

    function loadData() {
      parseStartEndInputDates();

      if (vm.editProgram.programType === 0) {
        if (vm.editProgram.curveIrrigation === 0) {
          vm.navs = {
            0: 'general',
            1: 'subprograms',
          };
          vm.tabs = {
            0: $filter('translate')('general.general'),
            1: $filter('translate')('programs.config.subprograms'),
          };
        } else {
          vm.navs = {
            0: 'general',
            1: 'subprograms',
            2: 'curves',
          };
          vm.tabs = {
            0: $filter('translate')('general.general'),
            1: $filter('translate')('programs.config.subprograms'),
            2: $filter('translate')('programs.curves'),
          };
        }
      } else {
        if (vm.editProgram.curveIrrigation === 0) {
          vm.navs = {
            0: 'general',
            1: 'linear',
            2: 'fertilizer',
          };
          vm.tabs = {
            0: $filter('translate')('general.general'),
            1: $filter('translate')('pivot.lineal'),
            2: $filter('translate')('programs.fert'),
          };
        } else {
          vm.navs = {
            0: 'general',
            1: 'linear',
            2: 'fertilizer',
            3: 'curves',
          };
          vm.tabs = {
            0: $filter('translate')('general.general'),
            1: $filter('translate')('pivot.lineal'),
            2: $filter('translate')('programs.fert'),
            3: $filter('translate')('programs.curves'),
          };
        }
      }

      update();
    }

    function parseStartEndInputDates() {
      const startDay = vm.editProgram.dayPeriod1;
      const startMonth = vm.editProgram.monthPeriod1;
      const endDay = vm.editProgram.dayPeriod2;
      const endMonth = vm.editProgram.monthPeriod2;

      [vm.from, vm.to] = [`${startDay}-${startMonth}`, `${endDay}-${endMonth}`];
      vm.isActivePeriodEnabled = startDay && startMonth && endDay && endMonth;
    }

    function toggleActivePeriod($event) {
      vm.isActivePeriodEnabled = $event.detail.checked;
      vm.formProgram.$setDirty();
    }

    function updateRangeDates($event) {
      const range = $event.detail;
      [vm.from, vm.to] = range;
      vm.editProgram.dayPeriod1 = +range[0].split('-')[0];
      vm.editProgram.monthPeriod1 = +range[0].split('-')[1];

      vm.editProgram.dayPeriod2 = +range[1].split('-')[0];
      vm.editProgram.monthPeriod2 = +range[1].split('-')[1];

      vm.formProgram.$setDirty();
    }

    function loadIrrigFormats() {
      vm.irrigFormats = {
        0: {
          riego: {
            suffix: 'hh:mm',
          },
          preriego: {
            suffix: 'hh:mm',
          },
          postriego: {
            suffix: 'hh:mm',
          },
        },
        1: {
          riego: {
            suffix: 'm3',
            decimals: vm.installer.irrigDecimals,
            integers: 5 - vm.installer.irrigDecimals,
            max: 65000 / Math.pow(10, vm.installer.irrigDecimals),
            irrigFactor: 1000,
          },
          preriego: {
            suffix: 'm3',
            decimals: vm.installer.irrigDecimals,
            integers: 5 - vm.installer.irrigDecimals,
            max: 65000 / Math.pow(10, vm.installer.irrigDecimals),
            irrigFactor: 1000,
          },
          postriego: {
            suffix: 'm3',
            decimals: vm.installer.irrigDecimals,
            integers: 5 - vm.installer.irrigDecimals,
            max: 65000 / Math.pow(10, vm.installer.irrigDecimals),
            irrigFactor: 1000,
          },
        },
        2: {
          riego: {
            suffix: 'm3/ha',
            decimals: 2,
            integers: 3,
            max: 650.0,
            irrigFactor: 1000,
          },
          preriego: {
            suffix: 'm3',
            decimals: vm.installer.irrigDecimals,
            integers: 5 - vm.installer.irrigDecimals,
            max: 65000 / Math.pow(10, vm.installer.irrigDecimals),
            irrigFactor: 1000,
          },
          postriego: {
            suffix: 'm3',
            decimals: vm.installer.irrigDecimals,
            integers: 5 - vm.installer.irrigDecimals,
            max: 65000 / Math.pow(10, vm.installer.irrigDecimals),
            irrigFactor: 1000,
          },
        },
        3: {
          riego: {
            suffix: 'mm:ss',
          },

          preriego: {
            suffix: 'mm:ss',
          },
          postriego: {
            suffix: 'mm:ss',
          },
        },
        4: {
          riego: {
            suffix: 'm3/ha(t)',
            decimals: 2,
            integers: 3,
            max: 650.0,
            irrigFactor: 1000,
          },
          preriego: {
            suffix: 'hh:mm',
          },
          postriego: {
            suffix: 'hh:mm',
          },
        },
        5: {
          riego: {
            suffix: 'mm',
            decimals: 2,
            integers: 2,
            max: 99.99,
            irrigFactor: 10000,
          },
          preriego: {
            suffix: 'm3',
            decimals: vm.installer.irrigDecimals,
            integers: 5 - vm.installer.irrigDecimals,
            max: 65000 / Math.pow(10, vm.installer.irrigDecimals),
            irrigFactor: 1000,
          },
          postriego: {
            suffix: 'm3',
            decimals: vm.installer.irrigDecimals,
            integers: 5 - vm.installer.irrigDecimals,
            max: 65000 / Math.pow(10, vm.installer.irrigDecimals),
            irrigFactor: 1000,
          },
        },
      };
    }

    function getIntegers(sector, irrigType) {
      if (vm.installer.prePostIrrigForSubprog === 1) {
        if (vm.editProgram.sectorsByGroup === 1) {
          return integersGroupBy1(sector, irrigType);
        } else {
          return integersGroupByMany(irrigType);
        }
      } else {
        return integersGroupByMany(irrigType);
      }
    }

    function integersGroupBy1(sector, irrigType) {
      if (irrigType === 'irrig') {
        return vm.irrigFormats[sector.irrigUnits].riego.integers;
      } else if (irrigType === 'preIrrig') {
        return vm.irrigFormats[sector.irrigUnits].preriego.integers;
      } else if (irrigType === 'postIrrig') {
        return vm.irrigFormats[sector.irrigUnits].postriego.integers;
      }
    }

    function integersGroupByMany(irrigType) {
      if (irrigType === 'irrig') {
        return vm.irrigFormats[vm.editProgram.irrigUnits].riego.integers;
      } else if (irrigType === 'preIrrig') {
        return vm.irrigFormats[vm.editProgram.irrigUnits].preriego.integers;
      } else if (irrigType === 'postIrrig') {
        return vm.irrigFormats[vm.editProgram.irrigUnits].postriego.integers;
      }
    }

    function getDecimals(sector, irrigType) {
      if (vm.installer.prePostIrrigForSubprog === 1) {
        if (vm.editProgram.sectorsByGroup === 1) {
          return decimalsGroupBy1(sector, irrigType);
        } else {
          return decimalsGroupByMany(irrigType);
        }
      } else {
        return decimalsGroupByMany(irrigType);
      }
    }

    function decimalsGroupBy1(sector, irrigType) {
      if (irrigType === 'irrig') {
        return vm.irrigFormats[sector.irrigUnits].riego.decimals;
      } else if (irrigType === 'preIrrig') {
        return vm.irrigFormats[sector.irrigUnits].preriego.decimals;
      } else if (irrigType === 'postIrrig') {
        return vm.irrigFormats[sector.irrigUnits].postriego.decimals;
      }
    }

    function decimalsGroupByMany(irrigType) {
      if (irrigType === 'irrig') {
        return vm.irrigFormats[vm.editProgram.irrigUnits].riego.decimals;
      } else if (irrigType === 'preIrrig') {
        return vm.irrigFormats[vm.editProgram.irrigUnits].preriego.decimals;
      } else if (irrigType === 'postIrrig') {
        return vm.irrigFormats[vm.editProgram.irrigUnits].postriego.decimals;
      }
    }

    function getIrrigMax(sector) {
      if (vm.installer.prePostIrrigForSubprog === 1) {
        if (vm.editProgram.sectorsByGroup === 1) {
          return vm.irrigFormats[sector.irrigUnits].riego.max;
        } else {
          return vm.irrigFormats[vm.editProgram.irrigUnits].riego.max;
        }
      } else {
        return vm.irrigFormats[vm.editProgram.irrigUnits].riego.max;
      }
    }

    function getPreIrrigMax(sector) {
      if (vm.installer.prePostIrrigForSubprog === 1) {
        if (vm.editProgram.sectorsByGroup === 1) {
          return vm.irrigFormats[sector.irrigUnits].preriego.max;
        } else {
          return vm.irrigFormats[vm.editProgram.irrigUnits].preriego.max;
        }
      } else {
        return vm.irrigFormats[vm.editProgram.irrigUnits].preriego.max;
      }
    }

    function getPostIrrigMax(sector) {
      if (vm.installer.prePostIrrigForSubprog === 1) {
        if (vm.editProgram.sectorsByGroup === 1) {
          return vm.irrigFormats[sector.irrigUnits].postriego.max;
        } else {
          return vm.irrigFormats[vm.editProgram.irrigUnits].postriego.max;
        }
      } else {
        return vm.irrigFormats[vm.editProgram.irrigUnits].postriego.max;
      }
    }

    function getConversionFactor(sector, irrigType) {
      if (vm.installer.prePostIrrigForSubprog === 1) {
        if (vm.editProgram.sectorsByGroup === 1) {
          return irrigFactorGroupBy1(sector, irrigType);
        } else {
          return irrigFactorGroupByMany(irrigType);
        }
      } else {
        return irrigFactorGroupByMany(irrigType);
      }
    }

    function irrigFactorGroupBy1(sector, irrigType) {
      if (irrigType === 'irrig') {
        return vm.irrigFormats[sector.irrigUnits].riego.irrigFactor;
      } else if (irrigType === 'preIrrig') {
        return vm.irrigFormats[sector.irrigUnits].preriego.irrigFactor;
      } else if (irrigType === 'postIrrig') {
        return vm.irrigFormats[sector.irrigUnits].postriego.irrigFactor;
      }
    }

    function irrigFactorGroupByMany(irrigType) {
      if (irrigType === 'irrig') {
        return vm.irrigFormats[vm.editProgram.irrigUnits].riego.irrigFactor;
      } else if (irrigType === 'preIrrig') {
        return vm.irrigFormats[vm.editProgram.irrigUnits].preriego.irrigFactor;
      } else if (irrigType === 'postIrrig') {
        return vm.irrigFormats[vm.editProgram.irrigUnits].postriego.irrigFactor;
      }
    }

    function getFertUnitsLabel() {
      return vm.unitFertValues[vm.editProgram.fertUnits];
    }

    function getFertTypeLabel() {
      return vm.unitTypeValues[vm.editProgram.fertType];
    }

    function changeNav(event) {
      event.preventDefault();
      if (vm.selectedNav !== vm.navs[event.detail.index]) {
        if (vm.formProgram && vm.formProgram.$dirty) {
          $confirm({
            text: $filter('translate')('programs.edit.cancelq'),
            title: $filter('translate')('programs.prog2'),
          }).then(() => {
            vm.formProgram.$setPristine(); //Actualitzem estat del formulari a inicial
            vm.indexSelectedNav = event.detail.index;
            vm.selectedNav = vm.navs[event.detail.index];
            update();
          });
        } else {
          vm.indexSelectedNav = event.detail.index;
          vm.selectedNav = vm.navs[event.detail.index];
          update();
        }
      }
      //vm.selectedNav = e;
    }

    function update() {
      updateGeneralTab();
      updateLinearTab();
      updateFertilizerTab();
      updateSubprogramsTab();
    }

    function updateGeneralTab() {
      if (vm.selectedNav == 'general' && !loadedGeneral) {
        let queries = [];
        queries.push(
          progFactory.getInstaller(vm.currentUnit.id),
          sectorFactory.allSectors(vm.currentUnit.id),
          sectorFactory.getGroupSectors(vm.currentUnit.id),
          progFactory.getProgramSectors(vm.currentUnit.id, vm.editProgram.pk.id),
          fertilizerFactory.getFertilizationHeaders(vm.currentUnit.id),
          configFactory.getWaterMix(vm.currentUnit.id)
        );
        $q.all(queries).then((response) => {
          loadInstaller(response[0]);
          loadAllSectors(response[1]);
          loadGroupSectors(response[2]);
          loadProgramSectors(response[3]);
          loadAllHeadersFertilizers(response[4]); // DEPENDECIA de l'id capçal
          loadHeaderWaterMix(response[5]); // DEPENDECIA de l'id capçal

          setHeader();
          setMaxDayMonthPeriods();

          // get first subprogram in order to set preirrig and postirrig
          if (vm.programSectorsArray.length === 0) {
            vm.programSectorsArray.push(programSectors[0]);
          }
        });

        loadedGeneral = true;
      }
    }

    function updateLinearTab() {
      if (vm.selectedNav == 'linear' && !loadedLinear) {
        copyProgramSectors();
        //loadAllSectors();
        //loadProgramSectors();

        disableLinearSectorsOnFirstLoad();

        // let totalAgroupations = (vm.programSectorsArray.length / vm.editProgram.sectorsByGroup >> 0) + 1;
        // for(let i = 0; i < totalAgroupations; i++){
        //     disableSectors(i*vm.editProgram.sectorsByGroup);
        // }
        loadedLinear = true;
      }
    }

    function showSecurityTimeColumn() {
      if (vm.installer.timeVolume) {
        if (vm.installer.prePostIrrigForSubprog === 1 && vm.editProgram.sectorsByGroup === 1) {
          return true;
        } else {
          const irrigUnits = vm.editProgram.irrigUnits;
          return irrigUnits === 1 || irrigUnits === 2 || irrigUnits === 5;
        }
      } else {
        return false;
      }
    }

    function showSecurityTime(programSector) {
      if (vm.installer.timeVolume) {
        let irrigUnits;
        if (vm.installer.prePostIrrigForSubprog === 1 && vm.editProgram.sectorsByGroup === 1) {
          irrigUnits = programSector.irrigUnits;
        } else {
          irrigUnits = vm.editProgram.irrigUnits;
        }
        return irrigUnits === 1 || irrigUnits === 2 || irrigUnits === 5;
      }
      return false;
    }

    function updateFertilizerTab() {
      if (vm.selectedNav == 'fertilizer' && !loadedFertilizer) {
        loadFitos();
        loadSubprograms();
        // loadHeaderFertilizers();
        loadedFertilizer = true;
      }
    }

    function updateSubprogramsTab() {
      if (vm.selectedNav == 'subprograms' && !loadedSubprograms) {
        loadSubprograms();
        // loadHeaderFertilizers();
        //loadAllSectors();
        //loadGroupSectorsElements();
        //loadProgramSectors();
        loadFitos();

        loadedSubprograms = true;
      }
    }

    function updateFertilizerValues(isRecentlyOpened) {
      const headersInSectors = Array.from(
        new Set([
          ...vm.allSectors
            .filter((sector) => vm.selectedSectorsIdList.includes(+sector.pk.id))
            .map((sector) => sector.header),
          ...vm.groupSectors
            .filter((sector) => vm.selectedSectorsIdList.includes(+sector.pk.id + 500))
            .map((sector) => sector.header),
        ])
      );

      const selectedSectorsHeader = headersInSectors.length === 1 ? headersInSectors[0] : 255;

      vm.sectorsDropdownErrorMessage = isRecentlyOpened
        ? ''
        : !headersInSectors.length
        ? $filter('translate')('programs_v2.alerts.no_sectors_selected')
        : selectedSectorsHeader === 255
        ? $filter('translate')('programs_v2.alerts.different_headers_error')
        : '';

      const subProgramSelectedIndex = vm.programSectorsArray.findIndex(
        (subprogram) => subprogram.pk.id === vm.subProgramSelected.pk.id
      );

      vm.programSectorsArray[subProgramSelectedIndex].header = selectedSectorsHeader;

      const subprogramsHeaders = Array.from(new Set(vm.programSectorsArray.map((sub) => sub.header)));

      const hasProgramError = (subprogramsHeaders.length > 1 || subprogramsHeaders === 255) && headersInSectors.length;

      vm.currentHeader = !hasProgramError ? selectedSectorsHeader : 255;
      vm.fertilizationHeader = fertilizationHeaders[selectedSectorsHeader - 1];
    }

    function setProgramHeader() {
      var prevHeader = 0;
      var nextHeader = 0;
      var programHeader = 0;
      for (let subprogram of vm.programSectorsArray) {
        nextHeader = subprogram.header;
        if ((prevHeader !== 0 && nextHeader !== 0 && prevHeader !== nextHeader) || nextHeader === 255) {
          vm.currentHeader = 255;
          return;
        }
        if (nextHeader !== 0) {
          programHeader = nextHeader;
        }
        prevHeader = nextHeader;
      }

      vm.currentHeader = programHeader;
    }

    function setSubprogramHeader() {
      var prevHeader = 0;
      var nextHeader = 0;
      var programHeader = 0;
      for (let i = 1; i <= 10; i++) {
        nextHeader = getSectorHeader(i);
        if (prevHeader !== 0 && nextHeader !== 0 && prevHeader !== nextHeader) {
          vm.subProgramSelected.header = 255;
          return;
        }
        if (nextHeader !== 0) {
          programHeader = nextHeader;
        }
        prevHeader = nextHeader;
      }
      vm.subProgramSelected.header = programHeader;
    }

    function updateSubprogram(selectedSectorId) {
      updateSectors();
      // setSubprogramHeader();
      updateSectorsState();
      setSectorsDisabledIfNecessary(selectedSectorId);
      // setHeader();
      setCeRegulationSensorError();
      setCeInputSensorError();
    }

    function getSectorHeader(index) {
      var sectorId = eval('vm.subProgramSelected.sector' + index);
      if (sectorId > 0 && sectorId <= 400) {
        return vm.allSectors[sectorId - 1].header;
      } else if (sectorId > 400) {
        return getGroupOfSectorsHeader(sectorId - 500);
      } else {
        return 0;
      }
    }

    function getGroupOfSectorsHeader(groupId) {
      return vm.groupSectors[groupId - 1].header;
      var groupElements = vm.groupSectors[groupId].elements;
      // var groupSectorsSectors = vm.groupSectorsElements.filter(sector => {
      //     return Number(sector.pk.sectorGroupId) === groupId && sector.sector !== 0;
      // });

      let prevHeader = 0;
      let actualHeader = 0;
      var groupHeader = 0;
      for (let sector of groupElements) {
        actualHeader = sector.header;
        if (prevHeader !== 0 && actualHeader !== 0 && prevHeader !== actualHeader) {
          return 255;
        }
        if (actualHeader !== 0) {
          groupHeader = actualHeader;
        }
        prevHeader = actualHeader;
      }
      return groupHeader;
    }

    function loadFitos() {
      progFactory.getFitos(vm.currentUnit.id).then((response) => {
        allFitos = response.plain();
        setCurrentHeaderFito();
      });
    }

    function loadHeaderWaterMix(response) {
      allWaterMix = response.plain();
      setCurrentHeaderWatermix();
    }

    function loadInstaller(response) {
      vm.installer = response.plain();
      loadIrrigFormats();
    }

    function loadGroupSectors(response) {
      vm.groupSectors = response.plain();
      //setSectorsDisabledFalse(vm.groupSectors);
    }

    function setHeader() {
      setProgramHeader();
      if (vm.currentHeader > 0 && vm.currentHeader <= 4) {
        setFertilizationHeader();
        setCurrentHeaderFito();
        setCurrentHeaderWatermix();
      }
    }

    function loadProgramSectors(response) {
      programSectors = response.plain();
      vm.totalSubprograms = programSectors.length;
      copyProgramSectors();
    }

    function setCurrentHeaderFito() {
      if (allFitos && vm.currentHeader !== 255) {
        vm.headerFito1 = allFitos[vm.currentHeader * 2 - 2];
        vm.headerFito2 = allFitos[vm.currentHeader * 2 - 1];
      }
    }

    function setCurrentHeaderWatermix() {
      if (vm.currentHeader > 0 && vm.currentHeader <= 4) {
        vm.headerWaterMix = allWaterMix[vm.currentHeader - 1];
      }
    }

    function copyProgramSectors() {
      vm.programSectorsArray = _.filter(programSectors, (sector) => {
        return sector.sector1 !== 0;
      });
      for (let subprogram of vm.programSectorsArray) {
        subprogram.active = true;
        setProgramSectorArrayOfSectors(subprogram);
      }
      programSectorsBackup = angular.copy(programSectors);
    }

    function setProgramSectorArrayOfSectors(programSector) {
      programSector.sectors = [];
      let sectorId = 0;
      for (let i = 1; i < 11; i++) {
        eval(`sectorId = programSector.sector${i}`);
        if (sectorId > 0) {
          programSector.sectors.push(sectorId);
        }
      }
    }

    function loadAllSectors(response) {
      vm.allSectors = response.plain();
      //setSectorsDisabledFalse(vm.allSectors);
    }

    function setSectorsDisabledFalse(sectors) {
      for (let sector of sectors) {
        sector.disabledDuplicated = false;
        sector.disabledMaximum = false;
      }
    }

    function loadHeaderFertilizers() {
      fertilizerFactory.getOneFertilizationHeader(vm.currentUnit.id, vm.currentHeader).then((response) => {
        vm.fertilizationHeader = response.plain(); //TODO: mirar si necessito tot l'objecte o nomes ceregulation
      });
    }

    function loadAllHeadersFertilizers(response) {
      fertilizationHeaders = response.plain(); //TODO: mirar si necessito tot l'objecte o nomes ceregulation
      vm.fertilizationHeader = fertilizationHeaders[0];
    }

    function setFertilizationHeader() {
      vm.fertilizationHeader = fertilizationHeaders[vm.currentHeader - 1];
      vm.TFmax = 65000 / 10 ** vm.fertilizationHeader.decimalsTF;
    }

    function copySubprograms() {
      if (!vm.programSectorsArray) return;
      vm.subprogramsArray = [];
      for (let i = 0; i < vm.programSectorsArray.length; i++) {
        vm.subprogramsArray.push(subprograms[i]);
      }
      subprogramsBackup = angular.copy(subprograms);
    }

    function loadSubprograms() {
      progFactory.subprograms(vm.currentUnit.id, vm.editProgram.pk.id).then((response) => {
        subprograms = response.plain();
        copySubprograms();
        if (vm.subprogramsArray.length > 0) {
          vm.subprogram = vm.subprogramsArray[0];
          prepareSubprogramFert(vm.subprogram);
        }
      });
    }

    function prepareSubprogramFert(subprogram) {
      vm.fertValuesProportional[0] = subprogram.proportionalParsedFertValue1_1;
      vm.fertValuesProportional[1] = subprogram.proportionalParsedFertValue2_1;
      vm.fertValuesProportional[2] = subprogram.proportionalParsedFertValue3_1;
      vm.fertValuesProportional[3] = subprogram.proportionalParsedFertValue4_1;
      vm.fertValuesProportional[4] = subprogram.proportionalParsedFertValue5_1;
      vm.fertValuesProportional[5] = subprogram.proportionalParsedFertValue6_1;
      vm.fertValuesProportional[6] = subprogram.proportionalParsedFertValue7_1;
      vm.fertValuesProportional[7] = subprogram.proportionalParsedFertValue8_1;

      vm.fertIrrigProportional[0] = subprogram.proportionalParsedFertValue1_2;
      vm.fertIrrigProportional[1] = subprogram.proportionalParsedFertValue2_2;
      vm.fertIrrigProportional[2] = subprogram.proportionalParsedFertValue3_2;
      vm.fertIrrigProportional[3] = subprogram.proportionalParsedFertValue4_2;
      vm.fertIrrigProportional[4] = subprogram.proportionalParsedFertValue5_2;
      vm.fertIrrigProportional[5] = subprogram.proportionalParsedFertValue6_2;
      vm.fertIrrigProportional[6] = subprogram.proportionalParsedFertValue7_2;
      vm.fertIrrigProportional[7] = subprogram.proportionalParsedFertValue8_2;

      vm.fertValues[0] = subprogram.fertValue1;
      vm.fertValues[1] = subprogram.fertValue2;
      vm.fertValues[2] = subprogram.fertValue3;
      vm.fertValues[3] = subprogram.fertValue4;
      vm.fertValues[4] = subprogram.fertValue5;
      vm.fertValues[5] = subprogram.fertValue6;
      vm.fertValues[6] = subprogram.fertValue7;
      vm.fertValues[7] = subprogram.fertValue8;
    }

    function setMaxDayMonthPeriods() {
      vm.maxDays = [];
      for (let i = 0; i < 5; i++) {
        let month = eval(`vm.editProgram.monthPeriod${i + 1}`);
        let days = moment('2016-' + month, 'YYYY-MM').daysInMonth();
        vm.maxDays.push(days);
      }
    }

    function changeMaxDays(index) {
      let month = eval(`vm.editProgram.monthPeriod${index}`);
      if (month > 0) {
        vm.maxDays[index - 1] = moment('2016-' + month, 'YYYY-MM').daysInMonth();
        if (eval(`vm.editProgram.dayPeriod${index}`) > vm.maxDays[index - 1]) {
          eval(`vm.editProgram.dayPeriod${index}=${vm.maxDays[index - 1]}`);
        }
      }
    }

    function openSubProgramWindow(index) {
      if (vm.programSectorsArray[index]) {
        vm.programSelectedIndex = index;
        vm.subProgramSelected = vm.programSectorsArray[index];
        subProgramSelectedCopy = angular.copy(vm.subProgramSelected); // create a copy of the programSector
        vm.subprogram = vm.subprogramsArray[index];
        subProgramFertSelectedCopy = angular.copy(vm.subprogram); // create a copy of the fertilizers of the programSector
        prepareSubprogramFert(vm.subprogram);
        loadSelectedSectors(vm.programSectorsArray[index]);
        updateSectorsState();
        setSectorsDisabledInFirstLoad();
        setCeRegulationSensorError();
        setCeInputSensorError();
        setInputsToShow();
        setSubprogramFormats();

        updateFertilizerValues(true);

        document.getElementById('vegga-modal-programs').show();
      }
    }

    /* ########## SUBPROGRAMS CODE ########## */

    function showSecurityTimeSubprograms() {
      if (!vm.installer) return;
      if (vm.installer.timeVolume && vm.subProgramSelected) {
        if (vm.installer.prePostIrrigForSubprog === 1) {
          const irrigUnits = vm.subProgramSelected.irrigUnits;
          return irrigUnits === 1 || irrigUnits === 2 || irrigUnits === 5;
        } else {
          const irrigUnits = vm.editProgram.irrigUnits;
          return irrigUnits === 1 || irrigUnits === 2 || irrigUnits === 5;
        }
      } else {
        return false;
      }
    }

    function setInputsToShow() {
      let irrigUnits;
      if (vm.installer.prePostIrrigForSubprog === 1) {
        irrigUnits = vm.subProgramSelected.irrigUnits;
      } else {
        irrigUnits = vm.editProgram.irrigUnits;
      }

      vm.showInputs = {};

      switch (irrigUnits) {
        case 0:
        case 3:
          vm.showInputs.riego = { volume: false };
          vm.showInputs.prepostriego = { volume: false };
          break;
        case 4:
          vm.showInputs.riego = { volume: true };
          vm.showInputs.prepostriego = { volume: false };
          break;
        case 1:
        case 2:
        case 5:
          vm.showInputs.riego = { volume: true };
          vm.showInputs.prepostriego = { volume: true };
          break;
      }
    }

    function setSubprogramFormats() {
      let irrigUnits;
      if (vm.installer.prePostIrrigForSubprog === 0) {
        irrigUnits = vm.editProgram.irrigUnits;
      } else {
        irrigUnits = vm.subProgramSelected.irrigUnits;
      }
      vm.subprogramFormats = {
        riego: {
          integers: vm.irrigFormats[irrigUnits].riego.integers,
          decimals: vm.irrigFormats[irrigUnits].riego.decimals,
          max: vm.irrigFormats[irrigUnits].riego.max,
          conversionFactor: vm.irrigFormats[irrigUnits].riego.irrigFactor,
          suffix: vm.irrigFormats[irrigUnits].riego.suffix,
        },
        prepostriego: {
          integers: vm.irrigFormats[irrigUnits].preriego.integers,
          decimals: vm.irrigFormats[irrigUnits].preriego.decimals,
          max: vm.irrigFormats[irrigUnits].preriego.max,
          conversionFactor: vm.irrigFormats[irrigUnits].preriego.irrigFactor,
          suffix: vm.irrigFormats[irrigUnits].preriego.suffix,
        },
      };
    }

    function getSubprogramIntegers() {
      if (vm.installer.prePostIrrigForSubprog === 0) {
        return vm.irrigFormats[vm.editProgram.irrigUnits].riego.integers;
      } else {
        return vm.irrigFormats[vm.subProgramSelected.irrigUnits].riego.integers;
      }
    }

    function getSubprogramDecimals() {
      if (vm.installer.prePostIrrigForSubprog === 0) {
        return vm.irrigFormats[vm.editProgram.irrigUnits].riego.integers;
      } else {
        return vm.irrigFormats[vm.subProgramSelected.irrigUnits].riego.integers;
      }
    }

    function getSubprogramMax() {
      if (vm.installer.prePostIrrigForSubprog === 0) {
        return vm.irrigFormats[vm.editProgram.irrigUnits].riego.integers;
      } else {
        return vm.irrigFormats[vm.subProgramSelected.irrigUnits].riego.integers;
      }
    }

    function setCeRegulationSensorError() {
      if (vm.subProgramSelected.header > 0 && vm.subProgramSelected.header <= 4) {
        vm.ceRegulationSensorError =
          vm.FERT_TYPE.EC_REGULATION == vm.editProgram.fertType &&
          fertilizationHeaders[vm.subProgramSelected.header - 1].ceRegulation == 0;
      } else {
        vm.ceRegulationSensorError = false;
      }
    }

    function setCeInputSensorError() {
      if (vm.subProgramSelected.header > 0 && vm.subProgramSelected.header <= 4) {
        vm.ceInputSensorError =
          vm.FERT_TYPE.EC_INPUT == vm.editProgram.fertType &&
          (fertilizationHeaders[vm.subProgramSelected.header - 1].ceRegulation == 0 ||
            fertilizationHeaders[vm.subProgramSelected.header - 1].ceInput == 0);
      } else {
        vm.ceInputSensorError = false;
      }
    }

    function updateSectorsState() {
      vm.totalSelectedSectors = countSelectedSectors();
      vm.duplicatedSectors = areSectorsDuplicated();
    }

    function areSectorsDuplicated() {
      let counter = {};
      for (let sectorId of vm.selectedSectorsIdList) {
        // if elements attribute doesn't exist, it means it is a sector, if it exists, it is a group
        if (sectorId <= 400) {
          if (counter[sectorId] !== undefined) {
            return true;
          }
          counter[sectorId] = 1;
        } else {
          let group = vm.groupSectors[sectorId - 501];
          for (let sec of group.elements) {
            let groupSectorId = sec.sector;
            if (groupSectorId > 0 && counter[groupSectorId] !== undefined) {
              return true;
            }
            counter[groupSectorId] = 1;
          }
        }
      }
      return false;
    }

    function setSectorsDisabledInFirstLoad() {
      setSectorsDisabledFalse(vm.allSectors);
      setSectorsDisabledFalse(vm.groupSectors);

      disableSectorsThatExceedMaximum();

      disableDuplicatedSectors();
    }

    function setSectorsDisabledIfNecessary(sectorId) {
      if (vm.totalSelectedSectors >= 40) {
        disableNotSelectedSectors();
        disableNotSelectedGroups();
      } else {
        ableSectorsSubprogram();
        ableAndDisableGroupsMaximum();
      }
      if (sectorId < 500) {
        if (vm.selectedSectorsIdList.includes(sectorId)) {
          disableGroupsContainingSector(sectorId);
          disableGroupsThatExceedMaximum();
        } else {
          ableGroupsContainingSector(sectorId);
          if (vm.totalSelectedSectors >= 40) {
            disableNotSelectedSectors();
            disableNotSelectedGroups();
          }
        }
      } else {
        if (vm.selectedSectorsIdList.includes(sectorId)) {
          disableSectorsContainedByGroup(sectorId - 500);
        } else {
          ableSectorsContainedByGroup(sectorId - 500);
        }
      }
    }

    function ableAndDisableGroupsMaximum() {
      for (let group of vm.groupSectors) {
        if (!vm.selectedSectorsIdList.includes(group.id + 500)) {
          if (vm.totalSelectedSectors + countSectorsInAGroup(group.id) > 40) {
            group.disabledMaximum = true;
          } else {
            group.disabledMaximum = false;
          }
        }
      }
    }

    function ableSectorsSubprogram() {
      for (let sector of vm.allSectors) {
        sector.disabledMaximum = false;
      }
    }

    function disableGroupsThatExceedMaximum() {
      for (let group of vm.groupSectors) {
        countSectorsInAGroup(group.id);

        var groupElements = vm.groupSectors[group.id - 1].elements;
        let sectors = groupElements.filter((sector) => {
          return sector.sector > 0;
        });

        return sectors.length;
      }
    }

    function ableSectorsContainedByGroup(groupId) {
      let group = vm.groupSectors.find((group) => group.id == groupId);
      for (let element of group.elements) {
        if (element.sector > 0) {
          for (let g of vm.groupSectors) {
            if (g.id !== group.id && g.elements.find((e) => e.sector == element.sector) !== undefined) {
              g.disabledDuplicated = false;
            }
          }
          vm.allSectors[element.sector - 1].disabledDuplicated = false;
        }
      }
    }

    function disableSectorsContainedByGroup(groupId) {
      let group = vm.groupSectors.find((group) => group.id == groupId);
      for (let element of group.elements) {
        if (element.sector > 0) {
          for (let g of vm.groupSectors) {
            if (g.id !== group.id && g.elements.find((e) => e.sector == element.sector) !== undefined) {
              g.disabledDuplicated = true;
            }
          }
          vm.allSectors[element.sector - 1].disabledDuplicated = true;
        }
      }
    }

    function ableGroupsContainingSector(sectorId) {
      for (let group of vm.groupSectors) {
        let duplicatedSectorId = group.elements.filter((element) => element.sector == sectorId);
        if (duplicatedSectorId.length > 0) {
          for (let element of group.elements) {
            if (vm.selectedSectorsIdList.includes(element.sector.toString())) {
              return;
            }
          }
          group.disabledDuplicated = false;
        }
      }
    }

    function disableGroupsContainingSector(sectorId) {
      for (let group of vm.groupSectors) {
        let duplicatedSectorId = group.elements.filter((element) => element.sector == sectorId);
        if (duplicatedSectorId.length > 0) {
          group.disabledDuplicated = true;
        }
      }
    }

    function disableDuplicatedSectors() {
      for (let sector of vm.selectedSectorsIdList) {
        // if elements attribute doesn't exist, it means it is a sector, if it exists, it is a group
        if (sector <= 400) {
          iterateGroupSectorsAndDissable(sector);
        } else {
          disableSectorsContainedInGroupSectors(sector - 500);
        }
      }
    }

    function disableSectorsThatExceedMaximum() {
      if (vm.totalSelectedSectors >= 40) {
        disableNotSelectedSectors();
        disableNotSelectedGroups();
      } else {
        // disable groups that if selected would make totalSelectedSectors surpass 40
        for (let group of vm.groupSectors) {
          let groupLength = group.elements.filter((element) => {
            return element.sector > 0;
          }).length;
          if (!isSectorSelected(group.id + 500) && vm.totalSelectedSectors + groupLength > 40) {
            group.disabledDuplicated = true;
          }
        }
      }
    }

    function countSelectedSectors() {
      let counter = 0;
      for (let sector of vm.selectedSectorsIdList) {
        // if elements attribute doesn't exist, it means it is a sector, if it exists, it is a group
        if (sector <= 400) {
          counter++;
        } else {
          counter += countSectorsInAGroup(sector - 500);
        }
      }
      return counter;
    }

    function disableSectorsContainedInGroupSectors(groupId) {
      var groupElements = vm.groupSectors[groupId - 1].elements;
      let selectedGroupDict = {}; // dictionary that contains the sectors of the selected group
      //iterate each element of group and disable it if is selected
      for (let sec of groupElements) {
        if (sec.sector > 0) {
          selectedGroupDict[sec.sector] = 1;
        }
        if (sec.sector > 0 && !isSectorSelected(sec.sector)) {
          vm.allSectors[sec.sector - 1].disabledDuplicated = true;
        }
      }

      for (let group of vm.groupSectors) {
        if (!isSectorSelected(group.id + 500)) {
          for (let sec of group.elements) {
            if (selectedGroupDict[sec.sector] !== undefined) {
              group.disabledDuplicated = true;
              break;
            }
          }
        }
      }
    }

    function disableNotSelectedGroups() {
      for (let group of vm.groupSectors) {
        if (!isSectorSelected(group.id + 500)) {
          group.disabledMaximum = true;
        }
      }
    }

    function iterateGroupSectorsAndDissable(sectorId) {
      for (let group of vm.groupSectors) {
        var groupElements = group.elements;
        var existsSector = groupElements.filter((element) => {
          return element.sector === Number(sectorId);
        });
        if (existsSector.length > 0 && !isSectorSelected(group.id + 500)) {
          group.disabledDuplicated = true;
        } else {
          group.disabledDuplicated = false;
        }
      }
    }

    function deleteSubprogram() {
      vm.programSectorsArray[vm.programSelectedIndex].active = false;
      vm.programSectorsArray.splice(vm.programSelectedIndex, 1);
      vm.subprogramsArray.splice(vm.programSelectedIndex, 1);

      document.getElementById('vegga-modal-programs').hide();
      vm.programSelectedIndex = null;
      vm.subProgramSelected = null;
      vm.formProgram.$setDirty();
    }

    function loadSelectedSectors(subProgram) {
      vm.selectedSectorsIdList = [];
      for (let i = 1; i < 11; i++) {
        if (eval(`subProgram.sector${i} > 0`)) {
          let sector;
          eval(`sector = getSectorObject(subProgram.sector${i});`);
          if (sector.elements === undefined) {
            vm.selectedSectorsIdList.push(Number(sector.pk.id));
          } else {
            vm.selectedSectorsIdList.push(Number(sector.pk.id) + 500);
          }
        }
      }
    }

    function getSectorObject(sectorId) {
      if (sectorId > 500) {
        return vm.groupSectors[sectorId - 501];
      } else {
        return vm.allSectors[sectorId - 1];
      }
    }

    function restoreSelectedSubprogram() {
      vm.programSectorsArray[vm.programSelectedIndex] = subProgramSelectedCopy;
      vm.subprogramsArray[vm.programSelectedIndex] = subProgramFertSelectedCopy;
      setHeader();
    }

    function cancelModule() {
      if (vm.subProgramSelected.active === false) {
        alert('Subprogram will be deleted');
        deleteSubprogram();
      } else {
        restoreSelectedSubprogram();
        vm.subProgramSelected = null;
        vm.programSelectedIndex = null;
        document.getElementById('vegga-modal-programs').hide();
      }
    }

    function checkFutureError() {
      const selectedSectorsHeadersArray = vm.selectedSectorsIdList.map((selectedId) => {
        if (selectedId > 500) {
          return vm.groupSectors[selectedId - 500 - 1].header;
        } else {
          return vm.allSectors[selectedId - 1].header;
        }
      });

      const flattenedSelectedSectorsHeadersArray = Array.from(new Set(selectedSectorsHeadersArray));
      vm.subProgramSelected.header =
        flattenedSelectedSectorsHeadersArray.length > 1 ? 255 : flattenedSelectedSectorsHeadersArray[0];

      const selectedProgramSectorsHeaders = vm.programSectorsArray.map((sector) => sector.header);
      const flattenedSelectedProgramSectorsHeaders = Array.from(new Set(selectedProgramSectorsHeaders));
      vm.currentHeader =
        flattenedSelectedProgramSectorsHeaders.length > 1 ? 255 : flattenedSelectedProgramSectorsHeaders[0];
    }

    function saveModule() {
      if (vm.selectedSectorsIdList.length === 0) {
        alert('Must select at least one sector or group of sectors');
      } else {
        updateSectors();
        updateAllFertValues();
        setProgramSectorArrayOfSectors(vm.subProgramSelected);
        document.getElementById('vegga-modal-programs').hide();
        vm.subProgramSelected.active = true;
        checkFutureError();
        vm.subProgramSelected = null;
        vm.programSelectedIndex = null;
        vm.formProgram.$dirty = true;
      }
    }

    function updateAllFertValues() {
      for (let i = 0; i < vm.fertValues.length; i++) {
        updateFertValue(i);
      }
    }

    function updateSectors() {
      for (let i = 0; i < 10; i++) {
        let sectorId = vm.selectedSectorsIdList[i];
        if (sectorId !== undefined) {
          // if elements attribute doesn't exist, it means it is a sector, if it exists, it is a group
          //if(sector.elements === undefined){
          if (sectorId < 500) {
            //var sectorId = Number(sector.pk.id);
            eval(`vm.subProgramSelected.sector${i + 1}` + `= sectorId`);
          } else {
            //var sectorId = Number(sector.id + 500);
            eval(`vm.subProgramSelected.sector${i + 1}` + `= sectorId`);
          }
        } else {
          eval(`vm.subProgramSelected.sector${i + 1}` + `= 0`);
        }
      }
    }

    function addProgramSector() {
      let newElementCopy = JSON.parse(JSON.stringify(programSectors[0]));
      for (let i = 1; i <= 10; i++) {
        eval(`newElementCopy.sector${i} = 0`);
      }
      newElementCopy.sector1 = 0;
      if (vm.programSectorsArray.length === 0) {
        newElementCopy.sector1 = 1;
      }
      newElementCopy.value = 0;
      newElementCopy.preIrrig = 0;
      newElementCopy.postIrrig = 0;
      newElementCopy.maxIrrigTime = 0;
      newElementCopy.pk.id = (vm.programSectorsArray.length + 1).toString();
      newElementCopy.active = false;
      setProgramSectorArrayOfSectors(newElementCopy);
      vm.programSectorsArray.push(newElementCopy);
      vm.subProgramSelected = newElementCopy;
    }

    function addSubProgram() {
      let newElementCopy = JSON.parse(JSON.stringify(subprograms[0]));
      for (let i = 1; i <= 8; i++) {
        eval(`newElementCopy.fertValue${i} = 0`);
        eval(`newElementCopy.proportionalParsedFertValue${i}_1 = 0`);
        eval(`newElementCopy.proportionalParsedFertValue${i}_2 = 0`);
      }
      newElementCopy.referenceCE1 = 0;
      newElementCopy.referenceCE2 = 0;
      newElementCopy.referenceCEPunto1 = 0;
      newElementCopy.referenceCEPunto2 = 0;
      newElementCopy.referencePH = 0;
      newElementCopy.fitosValue1 = 0;
      newElementCopy.fitosValue2 = 0;
      newElementCopy.pk.id = '0';
      vm.subprogramsArray.push(newElementCopy);
      vm.subprogram = newElementCopy;
    }

    function insertSubprogram() {
      addProgramSector();
      addSubProgram();
      setHeader();
      openSubProgramWindow(vm.programSectorsArray.length - 1);
      setSubprogramHeader();
      setHeader();
    }

    function disableNotSelectedSectors() {
      for (let sector of vm.allSectors) {
        if (!isSectorSelected(Number(sector.pk.id))) {
          sector.disabledMaximum = true;
        }
      }
    }

    function isSectorSelected(selectedSectorId) {
      const sectorTmp = vm.selectedSectorsIdList.find((sectorId) => sectorId === selectedSectorId);
      return sectorTmp ? true : false;
    }

    /* ########## SHARED CODE FOR LINEAR AND SUBPROGRAMS ########## */

    function countSectorsInAGroup(groupId) {
      var groupElements = vm.groupSectors[groupId - 1].elements;
      let sectors = groupElements.filter((sector) => {
        return sector.sector > 0;
      });

      return sectors.length;
    }

    function getSectorOrSectorGroupName(sectorNum) {
      if (sectorNum < 500) {
        return vm.allSectors[sectorNum - 1].name;
      } else {
        return vm.groupSectors[sectorNum - 501].name;
      }
    }

    /* ########## LINEAR CODE ########## */

    function disableLinearSectorsOnFirstLoad() {
      const totalAgroupations = (vm.programSectorsArray.length / vm.editProgram.sectorsByGroup) >> 0;
      vm.totalSelectedSectors = [];
      for (let i = 0; i < totalAgroupations; i++) {
        const agroupationProgramSectors = getAgroupationProgramSectors(i);
        const totalAgroupationSectors = countAgroupationSectors(agroupationProgramSectors);
        setMaximum(i, agroupationProgramSectors);
        setLinearSectorsDisabledFalse(i);
        setLinearGroupsDisabledFalse(i);
        disableLinearSectorsThatExceedMaximum(i, agroupationProgramSectors);
      }
    }

    function setMaximum(idx, agroupationProgramSectors) {
      vm.totalSelectedSectors[idx] = 0;
      for (let programSector of agroupationProgramSectors) {
        let sectorId = programSector.sector1;
        if (sectorId <= 400) {
          vm.totalSelectedSectors[idx]++;
        } else {
          vm.totalSelectedSectors[idx] += countSectorsInAGroup(sectorId - 500);
        }
      }
    }

    function disableLinearSectorsThatExceedMaximum(agroupationIndex, agroupationProgramSectors) {
      if (vm.totalSelectedSectors[agroupationIndex] >= 40) {
        disableLinearNotSelectedSectorsAndGroups(agroupationIndex, agroupationProgramSectors);
      } else {
        ableAndDisableLinearGroupsMaximum(agroupationIndex, agroupationProgramSectors);
      }
    }

    function ableAndDisableLinearGroupsMaximum(idx, agroupationProgramSectors) {
      var sectorsIds = [];
      for (let p of agroupationProgramSectors) {
        sectorsIds.push(p.sector1);
      }
      for (let group of vm.groupSectors) {
        if (!sectorsIds.includes(group.id + 500)) {
          const totalSectorsInGroup = group.elements.filter((element) => element.sector > 0).length;
          if (vm.totalSelectedSectors[idx] + totalSectorsInGroup > 40) {
            group.disabledMaximum[idx] = true;
          } else {
            group.disabledMaximum[idx] = false;
          }
        }
      }
    }

    function disableLinearNotSelectedSectorsAndGroups(idx, agroupationProgramSectors) {
      var sectorsIds = [];
      for (let p of agroupationProgramSectors) {
        sectorsIds.push(p.sector1);
      }
      for (let sector of vm.allSectors) {
        if (!sectorsIds.includes(Number(sector.pk.id))) {
          sector.disabledMaximum[idx] = true;
        } else {
          sector.disabledMaximum[idx] = false;
        }
      }

      for (let group of vm.groupSectors) {
        if (!sectorsIds.includes(group.id)) {
          group.disabledMaximum[idx] = true;
        } else {
          group.disabledMaximum[idx] = false;
        }
      }
    }

    function getAgroupationProgramSectors(agroupationIndex) {
      return vm.programSectorsArray.slice(
        agroupationIndex * vm.editProgram.sectorsByGroup,
        agroupationIndex * vm.editProgram.sectorsByGroup + vm.editProgram.sectorsByGroup
      );
    }

    function countAgroupationSectors(agroupationSectors) {
      let counter = 0;
      for (let sector of agroupationSectors) {
        if (sector.sector1 <= 400) {
          counter++;
        } else {
          counter += countSectorsInAGroup(sector.sector1 - 500);
        }
      }
      return counter;
    }

    function setLinearSectorsDisabledFalse(agroupationIndex) {
      for (let sector of vm.allSectors) {
        if (sector.disabledMaximum === undefined) {
          sector.disabledMaximum = [];
        }
        sector.disabledMaximum[agroupationIndex] = false;
      }
    }

    function setLinearGroupsDisabledFalse(agroupationIndex) {
      for (let group of vm.groupSectors) {
        if (group.disabledMaximum === undefined) {
          group.disabledMaximum = [];
        }
        group.disabledMaximum[agroupationIndex] = false;
      }
    }

    function deleteRow(index) {
      vm.programSectorsArray.splice(index, 1);
      updateLinearHeader();
    }

    function restartAgroupation(rowIndex) {
      let firstRowAgroupationIdx = Math.trunc(rowIndex / vm.editProgram.sectorsByGroup) * vm.editProgram.sectorsByGroup;
      let agroupationProgramSectors = vm.programSectorsArray.slice(
        firstRowAgroupationIdx,
        firstRowAgroupationIdx + vm.editProgram.sectorsByGroup
      );
      for (let programSector of agroupationProgramSectors) {
        programSector.sector1 = 1;
        programSector.value = 0;
        programSector.preIrrig = 0;
        programSector.postIrrig = 0;
        programSector.maxIrrigTime = 0;
      }
      setLinearHeader();
      // ableSectors(rowIndex);
      disableLinearSectorsOnFirstLoad();
    }

    function insertRow() {
      let newElementCopy = JSON.parse(JSON.stringify(programSectors[0]));
      for (let i = 1; i <= 10; i++) {
        eval(`newElementCopy.sector${i} = 0`);
      }
      newElementCopy.sector1 = 1;
      newElementCopy.value = 0;
      if (vm.installer.prePostIrrigForSubprog) {
        newElementCopy.preIrrig = 0;
        newElementCopy.postIrrig = 0;
      }
      newElementCopy.maxIrrigTime = 0;
      newElementCopy.pk.id = '0';
      vm.programSectorsArray.push(newElementCopy);
      newElementCopy.active = false;
      setLinearHeader(newElementCopy);

      disableLinearSectorsOnFirstLoad();
    }

    function updateLinearHeader() {
      setLinearHeader();
      vm.fertilizationHeader = fertilizationHeaders[vm.currentHeader - 1];
      disableLinearSectorsOnFirstLoad();
    }

    function setLinearHeader() {
      let currentHeader = 0;
      let nextHeader = 0;
      let programHeader = 0;
      for (let row of vm.programSectorsArray) {
        if (row.sector1 <= 400) {
          nextHeader = vm.allSectors[row.sector1 - 1].header;
        } else {
          nextHeader = vm.groupSectors[row.sector1 - 501].header;
        }

        if (nextHeader !== currentHeader && nextHeader !== 0 && currentHeader !== 0) {
          vm.currentHeader = 255;
          return;
        }

        if (nextHeader !== 0) {
          programHeader = nextHeader;
        }
        currentHeader = nextHeader;
      }
      vm.currentHeader = programHeader;
    }

    function findFirstHeader() {
      for (let sector of vm.programSectorsArray) {
        let sectorId = sector.sector1;
        let sectorHeader;
        if (sectorId <= 400) {
          sectorHeader = vm.allSectors[sectorId - 1].header;
        } else {
          sectorHeader = vm.groupSectors[sectorId - 501].header;
        }
        if (sectorHeader !== 0) {
          return sectorHeader;
        }
      }
      return 0;
    }

    function toggleFormat(sector) {
      sector.value = 0;
      sector.preIrrig = 0;
      sector.postIrrig = 0;
      sector.maxIrrigTime = 0;
      sector.irrigUnits = (sector.irrigUnits + 1) % Object.keys(vm.irrigFormats).length;
      if (vm.selectedNav == 'subprograms') {
        setSubprogramFormats();
        setInputsToShow();
      }
    }

    function checkWhichInputToShow(sector, irrigInput, irrigType, index) {
      if (sector === undefined || sector === null) {
        return false;
      }
      if (vm.installer.prePostIrrigForSubprog === 1 || vm.selectedNav === 'subprograms') {
        if (vm.editProgram.sectorsByGroup === 1 || vm.selectedNav === 'subprograms') {
          if (irrigInput === 'volume') {
            return (
              sector.irrigUnits === 1 ||
              sector.irrigUnits === 2 ||
              (sector.irrigUnits === 4 && irrigType === 'irrig') ||
              sector.irrigUnits === 5
            );
          } else if (irrigInput === 'time') {
            return (
              sector.irrigUnits === 0 || sector.irrigUnits === 3 || (sector.irrigUnits === 4 && irrigType !== 'irrig')
            );
          }
        } else {
          if (irrigType !== 'irrig' && index % vm.editProgram.sectorsByGroup !== 0) {
            return 'none';
          }
          if (irrigInput === 'volume') {
            if (irrigType === 'irrig' && vm.editProgram.irrigUnits === 4) {
              return true;
            }
            return (
              vm.editProgram.irrigUnits === 1 ||
              vm.editProgram.irrigUnits === 2 ||
              (vm.editProgram.irrigUnits === 4 && irrigType === 'irrig') ||
              vm.editProgram.irrigUnits === 5
            );
          } else if (irrigInput === 'time') {
            return (
              vm.editProgram.irrigUnits === 0 ||
              vm.editProgram.irrigUnits === 3 ||
              (vm.editProgram.irrigUnits === 4 && irrigType !== 'irrig')
            );
          }
        }
      } else {
        if (irrigType !== 'irrig' && index % vm.editProgram.sectorsByGroup !== 0) {
          return 'none';
        }
        if (irrigInput === 'volume') {
          if (irrigType === 'irrig' && vm.editProgram.irrigUnits === 4) {
            return true;
          }
          return (
            vm.editProgram.irrigUnits === 1 ||
            vm.editProgram.irrigUnits === 2 ||
            (vm.editProgram.irrigUnits === 4 && irrigType === 'irrig') ||
            vm.editProgram.irrigUnits === 5
          );
        } else if (irrigInput === 'time') {
          return (
            vm.editProgram.irrigUnits === 0 ||
            vm.editProgram.irrigUnits === 3 ||
            (vm.editProgram.irrigUnits === 4 && irrigType !== 'irrig')
          );
        }
      }
    }

    function checkIfHoursOrMinutes(irrigUnits) {
      if (!vm.installer || !vm.editProgram) return;
      // Pre and post irrigation by subprogram
      if (vm.installer.prePostIrrigForSubprog === 1) {
        // Type is subprogram
        if (vm.editProgram.programType === 0) {
          return irrigUnits === 0 || irrigUnits === 4;
        }
        // Type is linear
        else {
          if (vm.editProgram.sectorsByGroup <= 1) {
            return irrigUnits == 0 || irrigUnits === 4;
          } else {
            return vm.editProgram.irrigUnits === 0 || vm.editProgram.irrigUnits === 4;
          }
        }
      }
      // Pre and post irrigation by program
      else {
        return vm.editProgram.irrigUnits === 0 || vm.editProgram.irrigUnits === 4;
      }
    }

    function getIrrigationFormatSuffix(sector) {
      if (vm.installer.prePostIrrigForSubprog === 1 || vm.selectedNav === 'subprograms') {
        if (vm.editProgram.sectorsByGroup === 1 || vm.selectedNav === 'subprograms') {
          return vm.irrigFormats[sector.irrigUnits].riego.suffix;
        }
        return vm.irrigFormats[vm.editProgram.irrigUnits].riego.suffix;
      } else {
        return vm.irrigFormats[vm.editProgram.irrigUnits].riego.suffix;
      }
    }

    function getPreIrrigationFormatSuffix(sector) {
      if (vm.installer.prePostIrrigForSubprog === 1 || vm.selectedNav === 'subprograms') {
        if (vm.editProgram.sectorsByGroup === 1 || vm.selectedNav === 'subprograms') {
          return vm.irrigFormats[sector.irrigUnits].preriego.suffix;
        }
        return vm.irrigFormats[vm.editProgram.irrigUnits].preriego.suffix;
      } else {
        return vm.irrigFormats[vm.editProgram.irrigUnits].preriego.suffix;
      }
    }

    function getPostIrrigationFormatSuffix(sector) {
      if (vm.installer.prePostIrrigForSubprog === 1 || vm.selectedNav === 'subprograms') {
        if (vm.editProgram.sectorsByGroup === 1 || vm.selectedNav === 'subprograms') {
          return vm.irrigFormats[sector.irrigUnits].postriego.suffix;
        }
        return vm.irrigFormats[vm.editProgram.irrigUnits].postriego.suffix;
      } else {
        return vm.irrigFormats[vm.editProgram.irrigUnits].postriego.suffix;
      }
    }

    function updateFertValue(index) {
      if (index === 0) {
        vm.subprogram.fertValue1 = vm.fertValues[index];
        vm.subprogram.proportionalParsedFertValue1_1 = vm.fertValuesProportional[index];
        vm.subprogram.proportionalParsedFertValue1_2 = vm.fertIrrigProportional[index];
      } else if (index === 1) {
        vm.subprogram.fertValue2 = vm.fertValues[index];
        vm.subprogram.proportionalParsedFertValue2_1 = vm.fertValuesProportional[index];
        vm.subprogram.proportionalParsedFertValue2_2 = vm.fertIrrigProportional[index];
      } else if (index === 2) {
        vm.subprogram.fertValue3 = vm.fertValues[index];
        vm.subprogram.proportionalParsedFertValue3_1 = vm.fertValuesProportional[index];
        vm.subprogram.proportionalParsedFertValue3_2 = vm.fertIrrigProportional[index];
      } else if (index === 3) {
        vm.subprogram.fertValue4 = vm.fertValues[index];
        vm.subprogram.proportionalParsedFertValue4_1 = vm.fertValuesProportional[index];
        vm.subprogram.proportionalParsedFertValue4_2 = vm.fertIrrigProportional[index];
      } else if (index === 4) {
        vm.subprogram.fertValue5 = vm.fertValues[index];
        vm.subprogram.proportionalParsedFertValue5_1 = vm.fertValuesProportional[index];
        vm.subprogram.proportionalParsedFertValue5_2 = vm.fertIrrigProportional[index];
      } else if (index === 5) {
        vm.subprogram.fertValue6 = vm.fertValues[index];
        vm.subprogram.proportionalParsedFertValue6_1 = vm.fertValuesProportional[index];
        vm.subprogram.proportionalParsedFertValue6_2 = vm.fertIrrigProportional[index];
      } else if (index === 6) {
        vm.subprogram.fertValue7 = vm.fertValues[index];
        vm.subprogram.proportionalParsedFertValue7_1 = vm.fertValuesProportional[index];
        vm.subprogram.proportionalParsedFertValue7_2 = vm.fertIrrigProportional[index];
      } else if (index === 7) {
        vm.subprogram.fertValue8 = vm.fertValues[index];
        vm.subprogram.proportionalParsedFertValue8_1 = vm.fertValuesProportional[index];
        vm.subprogram.proportionalParsedFertValue8_2 = vm.fertIrrigProportional[index];
      }
    }

    function loadState(event) {
      const item = vm.tabs[event.detail.index];
      if (vm.form && vm.form.$dirty) {
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formFromUnitCancel'); //Emetem cancelació de canvis
          vm.form = null;

          if ($state.current.parent !== item.state) {
            $state.go(item.state, { unit: vm.currentUnit, config: item.config });
          }
        });
      } else {
        if (item && $state.current.parent !== item.state) {
          // toogledConfig = true;
          $state.go(item.state, { unit: vm.currentUnit });
        }
      }
    }

    function checkdirty(form) {
      if (vm.editProgram.backup) {
        form.$setDirty();
      }
    }

    function backup() {
      vm.program_backup = {};
      angular.copy(vm.editProgram, vm.program_backup);
      vm.editProgram.backup = true;
    }

    function saveForm(factory, elementToUpdate) {
      vm.editProgram.progtype = vm.currentUnit.type;
      factory.update(elementToUpdate).then(
        (response) => {
          $rootScope.$broadcast('updateEdit45', { message: 'refreshing' });
        },
        (error) => {
          $rootScope.toast({
            type: 'error',
            title: 'Error en el guardado',
          });
        }
      );
    }

    function updateProgramSectorValues() {
      for (let i = 0; i < programSectors.length; i++) {
        if (vm.programSectorsArray[i] === undefined) {
          programSectors[i].sector1 = 0;
          programSectors[i].pk.id = (i + 1).toString();
        } else {
          vm.programSectorsArray[i].pk.id = (i + 1).toString();
          programSectors[i] = vm.programSectorsArray[i];
        }
      }
    }

    // saves the edited subprograms contained into vm.subprogramsArray to subprograms setting the correct id for each one
    function updateSubprograms() {
      for (let i = 0; i < subprograms.length; i++) {
        if (vm.subprogramsArray[i] === undefined) {
          subprograms[i].pk.id = (i + 1).toString();
        } else {
          vm.subprogramsArray[i].pk.id = (i + 1).toString();
          subprograms[i] = vm.subprogramsArray[i];
        }
      }
    }

    function setModified1OnlyModifiedRows() {
      for (let i = 0; i < programSectors.length; i++) {
        if (!_.isEqual(programSectors[i], programSectorsBackup[i])) {
          programSectors[i].modified = 1;
        }
      }
    }

    function setModified1OnlyModifiedRowsFertilizer() {
      for (let i = 0; i < programSectors.length; i++) {
        if (!_.isEqual(subprograms[i], subprogramsBackup[i])) {
          subprograms[i].modified = 1;
        }
      }
    }

    function saveFormLinear(factory) {
      updateProgramSectorValues();
      setModified1OnlyModifiedRows();
      let modifiedProgramSectors = [];
      for (let s of programSectors) {
        if (s.modified == 1) {
          modifiedProgramSectors.push(s);
        }
      }
      if (modifiedProgramSectors.length > 0) {
        factory.updateProgramSectors(modifiedProgramSectors).then(
          (response) => {
            $rootScope.$broadcast('updateEdit45', { message: 'refreshing' });
          },
          (error) => {
            $rootScope.toast({
              type: 'error',
              title: 'Error en el guardado',
            });
          }
        );
      }
    }

    function saveFormFertilizer(factory, elementToUpdate) {
      updateSubprograms();
      setModified1OnlyModifiedRowsFertilizer();
      let modifiedSubprograms = [];
      for (let s of subprograms) {
        if (s.modified == 1) {
          modifiedSubprograms.push(s);
        }
      }
      // factory.updateSubprograms(elementToUpdate, vm.editProgram.fertType).then(response => {
      if (modifiedSubprograms.length > 0) {
        factory.updateSubprograms(modifiedSubprograms, vm.editProgram.fertType).then(
          (response) => {
            $rootScope.$broadcast('updateEdit45', { message: 'refreshing' });
          },
          (error) => {
            $rootScope.toast({
              type: 'error',
              title: 'Error en el guardado',
            });
          }
        );
      }
    }

    function saveFormSubprograms() {
      factory.updateProgramSectors(elementToUpdate).then(
        (response) => {
          $rootScope.$broadcast('updateEdit45', { message: 'refreshing' });
        },
        (error) => {
          $rootScope.toast({
            type: 'error',
            title: 'Error en el guardado',
          });
        }
      );
    }

    function parseActivePeriod() {
      if (vm.isActivePeriodEnabled) return;
      vm.editProgram.dayPeriod1 = 0;
      vm.editProgram.monthPeriod1 = 0;
      vm.editProgram.dayPeriod2 = 0;
      vm.editProgram.monthPeriod2 = 0;
    }

    function save(_ev, form) {
      vm.formProgram.$setPristine();

      if (vm.selectedNav === 'general') {
        parseActivePeriod();
        saveForm(progFactory, vm.editProgram);
        saveFormLinear(progFactory);
      } else if (vm.selectedNav === 'linear') {
        saveFormLinear(progFactory);
      } else if (vm.selectedNav === 'fertilizer') {
        saveFormFertilizer(progFactory, [vm.subprogram]);
      } else if (vm.selectedNav === 'subprograms') {
        saveFormLinear(progFactory, vm.programSectors);
        saveFormFertilizer(progFactory, subprograms);
      } else if (vm.selectedNav === 'curves') {
        if (!_.isEqual(vm.editProgram.progCurves, vm.program_backup.progCurves)) {
          vm.editProgram.modifiedCurves = 1;
        }
        saveForm(progFactory, vm.editProgram);
      }
    }

    function cancel_edition() {
      angular.copy(vm.program_backup, vm.editProgram);
    }

    /**
     * Emetem estat del formulari quan detecta canvis en l'objecte.
     */
    $scope.$watch(
      () => vm.formProgram,
      () => {
        $scope.$emit('formUpdated', vm.formProgram);
      }
    );

    /**
     * Event listener per detectar acció de save executada desde el parent controller.
     */
    $scope.$on('formSubmit', (e, args) => {
      save(e, vm.formProgram);
    });
    /**
     * Event listener per detectar acció de cancel·lar executada desde el parent controller.
     */
    $scope.$on('formCancel', (e, args) => {
      //Restaurem estat del formulari a la versió previa.
      cancel_edition();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
