import moment from 'moment/moment';
import { UNITS } from '../utils/units.enum';
import { OrganizationStore } from '@vegga/front-store';
import { getContainer } from '@vegga/front-store';
import { Subject, takeUntil } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .constant('moment', moment)

    .controller('manualController', manualController);

  manualController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'manualFactory',
    '$confirm',
    '$filter',
    'userFactory',
    'veggaModalConfirmService',
    'UserData',
    'unitFactory',
  ];

  function manualController(
    $log,
    $rootScope,
    $scope,
    $state,
    Restangular,
    $mdDialog,
    manualFactory,
    $confirm,
    $filter,
    userFactory,
    veggaModalConfirmService,
    UserData,
    unitFactory
  ) {
    var vm = this;
    vm.destroy$ = new Subject();
    vm.unitState;
    vm.unitHour;
    vm.manualStop;
    vm.manualExitStop;
    vm.manualOut;
    vm.manualExitOut;
    vm.newDay;
    vm.images;
    vm.isOwner = false;
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    activate();

    function activate() {
      const organizationStore = OrganizationStore.getInstance();

      vm.isOwner = organizationStore.getRole() === 'OWNER';

      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((unit) => {
        vm.currentUnit = unit;
        vm.isSyncDisabled = vm.currentUnit.sync.active || !vm.currentUnit.connected;
      });

      const syncStatus$ = vm.devicesFacade.currentDeviceSyncStatus$;
      syncStatus$.subscribe((syncStatus) => (vm.isSyncStatusActive = syncStatus));

      vm.UNITS = UNITS;

      vm.currentUnit.input = 1;
      vm.unitHour = unitHour;
      vm.manualStop = manualStop;
      vm.manualExitStop = manualExitStop;
      vm.manualOut = manualOut;
      vm.manualExitOut = manualExitOut;
      vm.manualDate = manualDate;
      vm.endMalfunction25 = endMalfunction25;
      vm.endAllMalfunctions7 = endAllMalfunctions7;
      vm.endMalfunctionsGeneral = endMalfunctionsGeneral;
      vm.endExitAlarms = endExitAlarms;
      vm.stopProgress = stopProgress;
      vm.syncConfigDevice = syncConfigDevice;
      vm.continueirrig = 1;
      vm.editingHour = false;
      vm.images = manualFactory.getManualImages();

      vm.OPERATION_TYPES_ENUM = {
        RECIEVE: 0,
        SEND: 1,
      };
    }

    function syncConfigDevice(operationType) {
      const toast = document.querySelector('vegga-toast-manager');
      veggaModalConfirmService
        .showVeggaModalConfirm(
          {
            header: 'Datos AGRONIC',
            content:
              operationType === vm.OPERATION_TYPES_ENUM.RECIEVE
                ? $filter('translate')('manual.recieve_unit_data_confirm')
                : $filter('translate')('manual.send_unit_data_confirm'),
            cancelText: $filter('translate')('general.cancel'),
            confirmText: $filter('translate')('general.accept'),
          },
          'sm'
        )
        .then((isConfirmed) => {
          if (isConfirmed) {
            vm.currentUnit.sync.unittype = vm.currentUnit.type;
            userFactory.configDevice(vm.currentUnit.id, operationType).then(() => {
              toast.create({
                message:
                  (operationType === operationType) === vm.OPERATION_TYPES_ENUM.RECIEVE
                    ? $filter('translate')('manual.recieve_unit_data_started')
                    : $filter('translate')('manual.send_unit_data_started'),
                statusClass: 'success',
              });
              vm.devicesFacade.currentDeviceSyncStatus = true;
            });
          }
        });
    }

    function manualStop() {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        switch (vm.currentUnit.type) {
          case UNITS.A_4000:
            manualFactory.stopUnit(vm.currentUnit).then((response) => {});
            break;
          case UNITS.A_2500:
          case UNITS.A_BIT:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 7;
            params.parameter1 = 1;
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
          case UNITS.A_7000:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 9;
            params.parameter1 = 1;
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
          case UNITS.A_5500:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 7;
            params.parameter1 = 1;
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
          case UNITS.A_4500:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 12;
            params.parameter1 = 1;
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
        }
        modalInstance.result.then((result) => {
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }

    function endMalfunction25() {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        if (vm.currentUnit.type === UNITS.A_4500) {
          params.action = 25;
        } else {
          params.action = 16;
        }
        params.parameter1 = vm.continueirrig;
        manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
        modalInstance.result.then((result) => {
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }

    function manualExitStop() {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        switch (vm.currentUnit.type) {
          case UNITS.A_4000:
            manualFactory.exitStopUnit(vm.currentUnit).then((response) => {});
            break;
          case UNITS.A_2500:
          case UNITS.A_BIT:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 7;
            params.parameter1 = 0;
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
          case UNITS.A_7000:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 9;
            params.parameter1 = 0;
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
          case UNITS.A_5500:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 7;
            params.parameter1 = 0;
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
          case UNITS.A_4500:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 12;
            params.parameter1 = 0;
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
        }
        modalInstance.result.then((result) => {
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }
    function manualOut() {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        manualFactory.outUnit(vm.currentUnit).then((response) => {
          //TODO
        });
        modalInstance.result.then((result) => {
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }

    function manualExitOut() {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        manualFactory.exitOutUnit(vm.currentUnit).then((response) => {
          //TODO
        });
        modalInstance.result.then((result) => {
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }

    function stopProgress() {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        params.action = 13;
        manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
        modalInstance.result.then((result) => {
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }

    function endExitAlarms() {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        params.action = 40;
        manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
        modalInstance.result.then((result) => {
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }

    function unitHour() {
      if (vm.currentUnit && vm.currentUnit.ram && vm.currentUnit.ram.date) {
        vm.newDate = moment(vm.currentUnit.ram.date, 'DD-MM-YYYY HH:mm')._d;
        vm.newTime = moment(vm.currentUnit.ram.date, 'DD-MM-YYYY HH:mm')._d;

        let dayWeek;
        dayWeek = moment(vm.currentUnit.ram.date, 'DD-MM-YYYY HH:mm').day();
        switch (dayWeek) {
          case 1:
            vm.newDay = '_mon';
            break;
          case 2:
            vm.newDay = '_tue';
            break;
          case 3:
            vm.newDay = '_wed';
            break;
          case 4:
            vm.newDay = '_thu';
            break;
          case 5:
            vm.newDay = '_fri';
            break;
          case 6:
            vm.newDay = '_sat';
            break;
          case 1:
            vm.newDay = '_sun';
            break;
        }
        return moment(vm.currentUnit.ram.date, 'DD-MM-YYYY HH:mm').format('DD/MM/YYYY | HH:mm');
      } else {
        return '--:--';
      }
    }

    function manualDate() {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        switch (vm.currentUnit.type) {
          case UNITS.A_4000:
            var params = {};
            params.type = '2';
            params.deviceId = vm.currentUnit.id;
            params.action = 20;
            params.parameter1 = moment(vm.newTime).hours();
            params.parameter2 = moment(vm.newTime).minutes();
            params.parameter3 = 0;
            params.parameter4 = moment(vm.newDate).date();
            params.parameter5 = moment(vm.newDate).month() + 1;
            params.parameter6 = Number(moment(vm.newDate).format('YY'));
            params.parameter7 = moment(vm.newDate).isoWeekday();
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
          case UNITS.A_2500:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 14;
            params.parameter1 = 0;
            params.parameter2 = 0;
            params.parameter3 = moment(vm.newTime).minutes();
            params.parameter4 = moment(vm.newTime).hours();
            params.parameter5 = moment(vm.newDate).date();
            params.parameter6 = moment(vm.newDate).month() + 1;
            params.parameter7 = Number(moment(vm.newDate).format('YY'));
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
          case UNITS.A_BIT:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 13;
            params.parameter1 = 0;
            params.parameter2 = 0;
            params.parameter3 = moment(vm.newTime).minutes();
            params.parameter4 = moment(vm.newTime).hours();
            params.parameter5 = moment(vm.newDate).date();
            params.parameter6 = moment(vm.newDate).month() + 1;
            params.parameter7 = Number(moment(vm.newDate).format('YY'));
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
          case UNITS.A_7000:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 23;
            params.parameter1 = 0;
            params.parameter2 = 0;
            params.parameter3 = moment(vm.newTime).minutes();
            params.parameter4 = moment(vm.newTime).hours();
            params.parameter5 = moment(vm.newDate).date();
            params.parameter6 = moment(vm.newDate).month() + 1;
            params.parameter7 = Number(moment(vm.newDate).format('YY'));
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
          case UNITS.A_5500:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 14;
            params.parameter1 = 0;
            params.parameter2 = 0;
            params.parameter3 = moment(vm.newTime).minutes();
            params.parameter4 = moment(vm.newTime).hours();
            params.parameter5 = moment(vm.newDate).date();
            params.parameter6 = moment(vm.newDate).month() + 1;
            params.parameter7 = Number(moment(vm.newDate).format('YY'));
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
          case UNITS.A_4500:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 24;
            params.parameter1 = 0;
            params.parameter2 = 0;
            params.parameter3 = moment(vm.newTime).minutes();
            params.parameter4 = moment(vm.newTime).hours();
            params.parameter5 = moment(vm.newDate).date();
            params.parameter6 = moment(vm.newDate).month() + 1;
            params.parameter7 = Number(moment(vm.newDate).format('YY'));
            manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
            break;
        }
        modalInstance.result.then((result) => {
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
        vm.editingHour = false;
      });
    }
    function endAllMalfunctions7() {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        params.action = 24;
        manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
        modalInstance.result.then((result) => {
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }

    function endMalfunctionsGeneral() {
      switch (vm.currentUnit.type) {
        case 2:
          var action;
          var parameters = null;
          switch (vm.currentUnit.status) {
            case 'generalMalfunction':
              action = 12;
              break;
            case 'flowMalfunction':
              action = 13;
              break;
            case 'counterMalfunction':
              action = 14;
              break;
            case 'ferlitzerMalfunction':
              action = 15;
              break;
            case 'filterMalfunction':
              action = 19;
              break;
            case 'phMalfunction':
              action = 16;
              break;
            case 'ceMalfunction':
              action = 17;
              break;
            case 'definitiveStopMalfunction':
              action = 18;
              parameters = 1;
              break;
            case 'systemStopMalfunction':
              action = 1;
              break;
            case '2lMalfunction':
              break;
          }
          $confirm({
            text: $filter('translate')('manual.send-accion'),
            title: $filter('translate')('manual.manual-agronic'),
            ok: $filter('translate')('manual.si'),
            cancel: $filter('translate')('manual.no'),
          }).then(function (res) {
            var modalInstance = manualFactory.showLoadingBar();
            manualFactory.stopMalfunction(vm.currentUnit, action, parameters).then((response) => {
              //TODO
            });
            modalInstance.result.then((result) => {
              $scope.$emit('reload', { message: 'reload' });
            });
          });

          break;

        case 3:
          var action;
          var parameters = null;
          switch (vm.currentUnit.status) {
            case 'filterMalfunction':
              action = 23;
              break;
            case 'systemStopMalfunction':
              action = 7;
              break;
            default:
              parameters = vm.currentUnit.input;
              action = 16;
              break;
          }
          $confirm({
            text: $filter('translate')('manual.send-accion'),
            title: $filter('translate')('manual.manual-agronic'),
            ok: $filter('translate')('manual.si'),
            cancel: $filter('translate')('manual.no'),
          }).then(function () {
            var modalInstance = manualFactory.showLoadingBar();
            manualFactory.stopMalfunction(vm.currentUnit, action, parameters).then((response) => {
              //TODO
            });
            modalInstance.result.then((result) => {
              $scope.$emit('reload', { message: 'reload' });
            });
          });

          break;

        case 4:
          var action;
          var parameters = null;
          switch (vm.currentUnit.status) {
            case 'definitiveStopMalfunction':
              action = 15; //El 22 no sutiltiza
              break;
            //FINAL PAROS I AVERIES
            default:
              parameters = vm.currentUnit.input;
              action = 15;
              break;
          }
          $confirm({
            text: $filter('translate')('manual.send-accion'),
            title: $filter('translate')('manual.manual-agronic'),
            ok: $filter('translate')('manual.si'),
            cancel: $filter('translate')('manual.no'),
          }).then(function (res) {
            var modalInstance = manualFactory.showLoadingBar();
            manualFactory.stopMalfunction(vm.currentUnit, action, parameters).then((response) => {
              //TODO
            });
            modalInstance.result.then((result) => {
              $scope.$emit('reload', { message: 'reload' });
            });
          });

          break;

        case 5:
          var action;
          var parameters = null;
          switch (vm.currentUnit.status) {
            case 'generalMalfunction':
              action = 14;
              break;
            case 'flowMalfunction':
              action = 17;
              break;
            case 'counterMalfunction':
              action = 14;
              break;
            case 'ferlitzerMalfunction':
              action = 15;
              break;
            case 'filterMalfunction':
              action = 11;
              break;
            case 'phMalfunction':
              action = 16;
              break;
            case 'ceMalfunction':
              action = 15;
              break;
            case 'definitiveStopMalfunction':
              action = 18;
              parameters = 1;
              break;
            case 'systemStopMalfunction':
              action = 1;
              break;
            case '2lMalfunction':
              break;
          }
          $confirm({
            text: $filter('translate')('manual.send-accion'),
            title: $filter('translate')('manual.manual-agronic'),
            ok: $filter('translate')('manual.si'),
            cancel: $filter('translate')('manual.no'),
          }).then(function (res) {
            var modalInstance = manualFactory.showLoadingBar();
            manualFactory.stopMalfunction(vm.currentUnit, action, parameters).then((response) => {
              //TODO
            });
            modalInstance.result.then((result) => {
              $scope.$emit('reload', { message: 'reload' });
            });
          });
          break;

        case 6:
          var action;
          var parameters = null;
          switch (vm.currentUnit.status) {
            case 'filterMalfunction':
              action = 23;
              break;
            case 'systemStopMalfunction':
              //action = 7;
              break;
            default:
              parameters = vm.currentUnit.input;
              action = 16;
              break;
          }
          $confirm({
            text: $filter('translate')('manual.send-accion'),
            title: $filter('translate')('manual.manual-agronic'),
            ok: $filter('translate')('manual.si'),
            cancel: $filter('translate')('manual.no'),
          }).then(function () {
            var modalInstance = manualFactory.showLoadingBar();
            manualFactory.stopMalfunction(vm.currentUnit, action, parameters).then((response) => {
              //TODO
            });
            modalInstance.result.then((result) => {
              $scope.$emit('reload', { message: 'reload' });
            });
          });
          break;
      }
    }

    $rootScope.$on('manual-actions-overlay-dismiss', () => {
      vm.destroy$.next();
      vm.destroy$.complete();
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });

    // $scope.$on('refresh', function (event, args) {
    //   if (args.unit) {
    //     vm.currentUnit = args.unit;
    //     angular.copy(args.unit, vm.currentUnit);
    //   }
    // });
  }
})();
