import moment from 'moment/moment';
import manual7popup from './manual-popup.html';
import { A7000_DEFAULT_CONFIG } from '../../utils/device-config';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('programsChildControllerA7', programsChildControllerA7)
    .controller('ModalInstanceCtrlProgram7', ModalInstanceCtrlProgram7);

  programsChildControllerA7.$inject = [
    '$log',
    '$scope',
    '$state',
    'progFactory',
    'unitFactory',
    'registerFactory',
    'resFactory',
    'NgTableParams',
    '$filter',
    'fertilizerFactory',
    '$rootScope',
    'sensorsFactory',
    '$uibModal',
    'manualFactory',
  ];

  function programsChildControllerA7(
    $log,
    $scope,
    $state,
    progFactory,
    unitFactory,
    registerFactory,
    resFactory,
    NgTableParams,
    $filter,
    fertilizerFactory,
    $rootScope,
    sensorsFactory,
    $uibModal,
    manualFactory
  ) {
    var vm = this;
    vm.activeList;
    vm.getSubprogramStartTime;

    var temporal_register;
    var editing = false;
    var sensorList;
    activate();

    function activate() {
      vm.viewDate = moment().format();

      vm.currentState = $state.includes;

      if ($state.params.program !== null) {
        vm.currentUnit = $state.params.unit;
        vm.tabIndex = 0;
        vm.program = $state.params.program;
        vm.validateActiveProgramFert = validateActiveProgramFert;
        vm.getTimeFromMins = progFactory.getTimeFromMins;
        vm.parseTimeFromMin = getTimeFromMin;
        vm.toDate = toDate;
        vm.toHour = toHour;
        vm.formatDate = formatDate;
        vm.selectedOp = '1';
        vm.getValueInUnit = getValueInUnit;
        vm.getFertF5Text55 = getFertF5Text55;
        vm.formatFert55 = formatFert55;
        vm.fertilizers = [];
        vm.cancel = cancel_edition;
        vm.save = save;
        vm.getSectorName = getSectorName;
        vm.openManualPopup = openManualPopup;
        vm.unitValues = A7000_DEFAULT_CONFIG.measurementUnits.irrigation;

        vm.irrigationMeasureUnit = vm.unitValues.find(
          (item) => item.deviceMeasurementUnitKey === vm.program.units
        ).measurementUnit;

        vm.unitFertValues = A7000_DEFAULT_CONFIG.measurementUnits.fertilization;

        vm.coditionerUnit = {
          1: 'W/m2',
          5: 'L/m2',
          6: 'ºC',
          7: 'W/m2',
        };
        switch ($state.current.name) {
          case 'a7programs.detail':
            loadRegisterData();
            //loadFertilizerParameters();
            loadConditioners();
            prepareSectorData();
            vm.goToSector = goToSector;
            vm.viewChangeClicked = viewChangeClicked;
            vm.conditionersActive = vm.program.conditioners.some((conditioner) => conditioner.type > 0);

            vm.stateStart = vm.program.xState & 0x01 || vm.program.xState & 0x80;
            break;
          case 'a7programs.edit':
            // if(!vm.program.backup){
            backup();
            // }
            editing = true;
            sensorsFactory.analogsPage(vm.currentUnit.id, { limit: 100, page: 1 }).then((sensors) => {
              sensorList = sensors.content;
              prepareShowConditioner(vm.program.conditioners);
            });
            vm.fertUnit = vm.unitFertValues.find(
              (val) => val.deviceMeasurementUnitKey === vm.currentUnit.flow.fertilizationUnit
            ).measurementUnit;

            //prepareSectorData();
            //prepareFertDecimals();
            //prepareIrrigationValues(true);
            //vm.activeHour = vm.currentUnit.installer.activeSchedule;
            // vm.activePeriod = vm.currentUnit.installer.activePeriod;

            break;
          case 'a7programs.config':
            // if(!vm.program.backup){
            backup();
            // }
            vm.secRange = _.range(0, 25);
            vm.groupRange = _.range(0, 33);
            break;
        }

        vm.priorValues = _.range(0, 100);

        /*vm.events = progFactory.getEventTable(vm.program,moment().format("MM-YYYY"));*/
      } else {
        $state.go('units');
      }
    }

    function prepareShowConditioner(conditioners) {
      _.each(conditioners, (c) => {
        switch (c.sensor) {
          case 1: //Energia Solar
            if (vm.currentUnit.installer) {
              c.units1 = vm.currentUnit.installer.radiationUnits === 0 ? 'Wh/m2' : 'J/cm2';
            } else {
              c.units1 = 'Wh/m2';
            }
            if (c.type === 1) {
              //F. Inici
              c.v3 = true; //Inicio de riego + Energia Solar;
              c.show = true;
            } else if (c.type === 2) {
              //F. Riego
              c.v3 = c.v2 = true; //Factor riego + Energia Solar;
              c.show = true;
            } else if (c.type === 5) {
              //F. Freq
              c.v3 = c.v2 = true; //Factor frecuencia + Energia Solar;
              c.show = true;
            }
            c.integers1 = 4;
            c.integers2 = 2;
            c.decimals1 = 0;
            c.decimals2 = 0;
            break;
          case 2: //Nivel Bandeja
            if (c.value1 !== 0) {
              c.units1 = 'mm';
              c.v1 = true; //Inicio de riego + Nivel Bandeja;
              c.show = true;
              c.integers1 = 3;
              c.decimals1 = 0;
            }
            break;
          case 3: //Inicio por sensor
            c.v1 = true;
            c.show = true;
            if (sensorList !== null && c.value1 > 0) {
              let index = c.value1 - 1;
              let s = sensorList[index];
              //c.value1 = c.min;
              if (s && s.format) {
                c.units1 = s.format.units;
                c.integers1 = s.format.integers;
                c.decimals1 = s.format.decimals;
              }
            }

            break;
          case 4: //Drenaje
            c.v2 = true;
            c.show = true;
            c.integers2 = 2;
            c.decimals2 = 0;
            break;
          case 5: //Cond. lluvia
            c.v1 = c.v2 = true; //Factor riego + Lluvia;
            c.show = true;
            c.integers1 = 2;
            c.decimals1 = 1;
            c.integers2 = 2;
            c.decimals2 = 0;
            c.units1 = 'L/m2';
            break;
          case 6: //Temp. Amb
            c.v1 = true; //Inicio por sensor
            c.show = true;
            c.units1 = 'ºCh';
            c.integers1 = 3;
            c.decimals1 = 0;
            break;
          case 7: //Radiación
            c.integers1 = 4;
            c.decimals1 = 0;
            c.integers2 = 2;
            c.decimals2 = 0;
            if (c.type === 2) {
              //F. Riego
              c.v1 = c.v2 = true; //Factor de riego + Radiación
              c.show = true;
            } else if (c.type === 5) {
              //F. Freq
              c.v1 = c.v2 = true; //Factor frecuencia + Energia Solar;
              c.show = true;
            }
            break;
          default:
            c.show = false;
        }
      });
    }
    function getSectorName(id) {
      let sector = _.find(vm.currentUnit.sectors, (s) => {
        return Number(s.pk.id) === id;
      });
      return sector ? sector.name : '';
    }
    function prepareSectorData() {
      vm.program._xCEFactor = vm.program.xCEFactor !== null ? vm.program.xCEFactor / 10 : 0;
      vm.program._xFertFactor = vm.program.xFertFactor !== null ? vm.program.xFertFactor / 10 : 0;
      vm.program._xIrrigationFactor = vm.program.xIrrigationFactor !== null ? vm.program.xIrrigationFactor / 10 : 0;
      vm.program._xFreqFactor = vm.program.xFreqFactor !== null ? vm.program.xFreqFactor / 10 : 0;
    }

    function prepareIrrigationValues(input) {
      switch (vm.program.unit) {
        case 2:
        case 1: {
          if (input) {
            vm.program.preIrrigation = vm.program.preIrrigation / 1000;
            vm.program.postIrrigation = vm.program.postIrrigation / 1000;
            _.forEach(vm.program.programSector, (p) => {
              p.value = p.value / 1000;
            });
          } else {
            vm.program.preIrrigation = vm.program.preIrrigation * 1000;
            vm.program.postIrrigation = vm.program.postIrrigation * 1000;
            _.forEach(vm.program.programSector, (p) => {
              p.value = p.value * 1000;
            });
          }
        }
      }
    }

    function toDate(date) {
      return moment(date, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY');
    }

    function toHour(date) {
      return moment(date, 'DD-MM-YYYY HH:mm').format('HH:mm');
    }

    function goToSector(id) {
      $state.go('sectors', { unit: vm.currentUnit, load: id });
    }

    function getStartTime() {
      var total = 0;
      var parts = vm.program.irrigationHour.split(':');
      for (var i = 0; i < index; i++) {
        total = total + vm.activeSubList[i].totalTime;
      }
      var hoursToMin = (parseInt(parts[0]) * 60) | 0;
      var temp = hoursToMin + parseInt(parts[1]) + total;
      if (temp > 1440) {
        temp = temp - 1440;
      }
      var irrigationHour = progFactory.getTimeFromMins(temp);
      return new Date(irrigationHour._d);
    }

    function getTimeFromMin(min) {
      if (_.isNumber(min)) {
        return progFactory.getTimeFromMins(min).format('HH:mm');
      }
      return min;
    }
    function viewChangeClicked(nextView) {
      if (nextView === 'year' || nextView === 'day' || nextView === 'week') {
        return false;
      }
    }

    function validateActiveProgramFert() {
      //return vm.currentUnit.fertilizer.xNumProgram === Number(vm.program.pk.id);
    }

    function successRegister(data) {
      var register = data.plain();

      if (register.content !== undefined && register.content.length > 0) {
        registerFactory.parseRegister('5', register.content);
        var response = register.content;
        vm.tableParams.total(register.totalElements);
        return response;
      }
    }

    function loadConditioners() {
      // resFactory.cond(vm.currentUnit.type).then(function(response){

      // vm.currentUnit.conditioners = response;
      vm.currentUnit.conditioners = resFactory.cond(vm.currentUnit.type);
      var conditioners = [];
      if (vm.program.condId1 !== 0) {
        var cond = vm.currentUnit.conditioners[vm.program.condId1 - 1];
        if (cond !== undefined) {
          conditioners.push(cond);
        }
      }
      if (vm.program.condId2 !== 0) {
        var cond = vm.currentUnit.conditioners[vm.program.condId2 - 1];
        if (cond !== undefined) {
          conditioners.push(cond);
        }
      }
      if (vm.program.condId3 !== 0) {
        var cond = vm.currentUnit.conditioners[vm.program.condId3 - 1];
        if (cond !== undefined) {
          conditioners.push(cond);
        }
      }
      if (vm.program.condId4 !== 0) {
        var cond = vm.currentUnit.conditioners[vm.program.condId4 - 1];
        if (cond !== undefined) {
          conditioners.push(cond);
        }
      }
      if (vm.program.condId5 !== 0) {
        var cond = vm.currentUnit.conditioners[vm.program.condId5 - 1];
        if (cond !== undefined) {
          conditioners.push(cond);
        }
      }
      vm.conditionerList = conditioners;
      // })
    }

    function loadRegisterData() {
      vm.tableParams = new NgTableParams(
        {
          page: 1,
          count: 15,
          filter: { type: '' },
        },
        {
          counts: [],
          getData: function (params) {
            var pageNumber = 1;
            if (params._params) {
              pageNumber = params._params.page;
            }

            if (params._params.filter.type !== '') {
              var type = params._params.filter.type;
              return unitFactory
                .programRegisters(vm.program.pk.deviceId, vm.program.pk.id, type, pageNumber)
                .then(successRegister);
            } else {
              return unitFactory
                .programRegisters(vm.program.pk.deviceId, vm.program.pk.id, null, pageNumber)
                .then(successRegister);
            }
          },
        }
      );
    }

    function getValueInUnit(input) {
      if (!_.isNumber(input)) {
        input = 0;
      }
      switch (vm.program.units) {
        case 0:
          return $filter('parsetime')(input * 60);
        case 1:
          return $filter('parsetimesec')(input);
        case 2:
          return input + 'm3';
        case 3:
          return input + 'L';
      }
    }

    function getFertF5Text55(fert) {
      switch (fert.xState) {
        case 0:
          return '-';
        case 1:
          return $filter('translate')('filters.wait');
        case 2:
          return vm.formatFert55(fert.xVolume, true);
        case 3:
          return $filter('translate')('general.pause');
      }
    }

    function formatFert55(val, f5) {
      var format = vm.currentUnit.fertilizer.fertUnits;
      var decimals = vm.currentUnit.fertilizer.fertDecimals;
      if (f5) {
        format = vm.currentUnit.fertilizer.fertUnitsF5;
        decimals = vm.currentUnit.fertilizer.fertDecimalsF5;
      }
      switch (format) {
        case 0: // hh:mm
          return $filter('parsetime')(val, true);
          break;
        case 1: // mm' ss"
          var m = (val / 60) | 0,
            s = val % 60 | 0;
          return m + '" ' + s + "'";
          break;
        case 2: // l
          switch (decimals) {
            case 0:
              return val + ' l';
            case 1:
              return val / 10 + ' l';
            case 2:
              return val / 100 + ' l';
          }
          break;
        case 3: // l/Ha
          switch (decimals) {
            case 0:
              return val + ' l/Ha';
            case 1:
              return val / 10 + ' l/Ha';
            case 2:
              return val / 100 + ' l/Ha';
          }
          break;
      }
    }

    function formatDate(date) {
      return moment(date).format('MMMM-YYYY');
    }

    function prepareFertDecimals() {
      vm.fertUnits = vm.currentUnit.fertilizer.fertUnits;

      if (!vm.program.fertloaded && vm.program.fertType > 0) {
        switch (vm.fertUnits) {
          case 2:
          case 3:
            vm.program.fertilizer1 = vm.program.fertilizer1 / 100;
            vm.program.fertilizer2 = vm.program.fertilizer2 / 100;
            vm.program.fertilizer3 = vm.program.fertilizer3 / 100;
            vm.program.fertilizer4 = vm.program.fertilizer4 / 100;
            break;
        }
      }
      if (!vm.program.fertloaded) {
        switch (vm.currentUnit.fertilizer.fertUnitsF5) {
          case 2:
          case 3:
            vm.program.fertilizer5 = vm.program.fertilizer5 / 100;
            break;
        }
      }
      vm.program.fertloaded = true;
    }

    function getCurrentFertilizer(index) {
      return vm.fertilizers[index];
    }

    function loadFertilizerParameters() {
      fertilizerFactory.all(vm.currentUnit.id).then((fert) => {
        vm.fertilizers = fert.fertilizers;
      });
    }

    function prepareFertilizationValues() {
      vm.fertUnits = vm.currentUnit.fertilizer.fertUnits;

      if (vm.program.fertType > 0) {
        switch (vm.fertUnits) {
          case 2:
          case 3:
            vm.program.fertilizer1 = vm.program.fertilizer1 * 100;
            vm.program.fertilizer2 = vm.program.fertilizer2 * 100;
            vm.program.fertilizer3 = vm.program.fertilizer3 * 100;
            vm.program.fertilizer4 = vm.program.fertilizer4 * 100;
            break;
        }
      }
      switch (vm.currentUnit.fertilizer.fertUnitsF5) {
        case 2:
        case 3:
          vm.program.fertilizer5 = vm.program.fertilizer5 * 100;
          break;
      }
    }

    function backup() {
      vm.program_backup = {};
      angular.copy(vm.program, vm.program_backup);
      vm.program.backup = true;
    }

    function cancel_edition() {
      angular.copy(vm.program_backup, vm.program);
      $state.go('programs.detail', { unit: vm.currentUnit, program: vm.program_backup });
    }

    function save() {
      if (vm.form) vm.form.$setPristine();
      if (vm.formProgram) vm.formProgram.$setPristine();
      vm.program.progtype = vm.currentUnit.type;
      if (editing) {
        prepareIrrigationValues();
        prepareFertilizationValues();
      }
      progFactory.update(vm.program).then(() => $rootScope.$broadcast('updateEdit7', { message: 'refreshing' }));
    }

    vm.ok = function () {
      $uibModalInstance.close(vm.selectedProg);
    };

    vm.cancelModal = function () {
      $uibModalInstance.dismiss('cancel');
    };

    function openManualPopup() {
      var modalInstance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        template: manual7popup,
        controller: 'ModalInstanceCtrlProgram7',
        controllerAs: 'vm',
        resolve: {
          program: function () {
            return vm.program;
          },
          unit: function () {
            return vm.currentUnit;
          },
        },
      });

      modalInstance.result.then(
        function (selectedItem) {
          var modalInstance = manualFactory.showLoadingBar();
          manualFactory.start(vm.currentUnit, vm.program.pk.id, selectedItem).then((response) => {});

          modalInstance.result.then((result) => {
            //                $state.go('programs',{unit:vm.currentUnit},{reload:true});
            //                $scope.$emit('reload',{message:'reload'});
            $rootScope.$broadcast('reload', { message: 'refreshing' });
          });
        },
        function () {
          $log.info('Modal dismissed at: ' + new Date());
        }
      );
    }

    $scope.$watch(
      function () {
        return moment(vm.viewDate).format('MM-YYYY');
      },
      function (month) {
        vm.events = progFactory.getEventTable(vm.program, month);
      }
    );

    /**
     * Emit form state to the parent
     */
    $scope.$watch(
      () => vm.formProgram,
      () => $scope.$emit('formUpdated', vm.formProgram)
    );

    /**
     * Capture save event from parent
     */
    $scope.$on('formSubmit', (e) => save(e));

    /**
     * Capture cancel event from parent
     */
    $scope.$on('formCancel', (e, args) => {
      // Restore form
      cancel_edition();
    });
  }

  ModalInstanceCtrlProgram7.$inject = ['$uibModalInstance', 'program', 'unit', '$confirm', 'manualFactory', '$state'];

  function ModalInstanceCtrlProgram7($uibModalInstance, program, unit, $confirm, manualFactory, $state) {
    var vm = this;
    vm.program = program;
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
    vm.stateStart = vm.program.xState & 0x01 || vm.program.xState & 0x80;
    vm.stateSec = vm.program.xState & 0x40;
    vm.manualStart = manualStart;
    vm.manualStop = manualStop;
    vm.manualOutOf = manualOutOf;
    vm.manualRunOf = manualRunOf;
    vm.manualStopSecurity = manualStopSecurity;
    vm.manualChangeFreq = manualChangeFreq;
    vm.manualChangeAct = manualChangeAct;
    vm.xFreqDays = vm.program.xFreqDays;
    vm.xFreqTime = vm.program.xFreqTime;
    vm.xActivations = vm.program.xActivations;

    function manualStart() {
      $uibModalInstance.dismiss('cancel');
      $confirm({ text: 'Enviar inicio manual?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(function () {
        var params = {};
        params.type = 5;
        params.deviceId = vm.program.pk.deviceId;
        params.action = 2;
        params.parameter1 = Number(vm.program.pk.id) - 1;
        manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
        var modalInstance = manualFactory.showLoadingBar();
        modalInstance.result.then((result) => {
          $state.go('a7programs', { unit: unit }, { reload: 'a7programs' });
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function manualStop() {
      $uibModalInstance.dismiss('cancel');
      $confirm({ text: 'Quiere enviar un paro de programa?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(
        function () {
          var params = {};
          params.type = 5;
          params.deviceId = vm.program.pk.deviceId;
          params.action = 3;
          params.parameter1 = Number(vm.program.pk.id) - 1;
          manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
          var modalInstance = manualFactory.showLoadingBar();
          modalInstance.result.then((result) => {
            $rootScope.$broadcast('updateEdit7', { message: 'refreshing' });
          });
        }
      );
    }

    function manualOutOf() {
      $uibModalInstance.dismiss('cancel');
      $confirm({ text: 'Enviar fuera de servicio?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(
        function () {
          var params = {};
          params.type = 5;
          params.deviceId = vm.program.pk.deviceId;
          params.action = 0;
          params.parameter1 = Number(vm.program.pk.id) - 1;
          params.parameter2 = 1;
          manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
          var modalInstance = manualFactory.showLoadingBar();
          modalInstance.result.then((result) => {
            $rootScope.$broadcast('updateEdit7', { message: 'refreshing' });
          });
        }
      );
    }

    function manualRunOf() {
      $uibModalInstance.dismiss('cancel');
      $confirm({ text: 'Enviar orden de activacion?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(
        function () {
          var params = {};
          params.deviceId = vm.program.pk.deviceId;
          params.type = 5;
          params.action = 0;
          params.parameter1 = Number(vm.program.pk.id) - 1;
          params.parameter2 = 0;
          manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
          var modalInstance = manualFactory.showLoadingBar();
          modalInstance.result.then((result) => {
            $rootScope.$broadcast('updateEdit7', { message: 'refreshing' });
          });
        }
      );
    }

    function manualStopSecurity() {
      $uibModalInstance.dismiss('cancel');
      $confirm({ text: 'Enviar orden?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(function () {
        var params = {};
        params.deviceId = vm.program.pk.deviceId;
        params.type = 1;
        params.action = 0;
        params.parameter1 = Number(vm.program.pk.id) - 1;
        params.parameter2 = 0;
        manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
        var modalInstance = manualFactory.showLoadingBar();
        modalInstance.result.then((result) => {
          $rootScope.$broadcast('updateEdit7', { message: 'refreshing' });
        });
      });
    }

    function manualChangeFreq() {
      $uibModalInstance.dismiss('cancel');
      $confirm({ text: 'Enviar?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(function () {
        var params = {};
        params.type = 4;
        params.deviceId = vm.program.pk.deviceId;
        params.action = 4;
        params.parameter1 = Number(vm.program.pk.id) - 1;
        params.parameter2 = Number(vm.xFreqDays);
        manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
        var modalInstance = manualFactory.showLoadingBar();
        modalInstance.result.then((result) => {
          $rootScope.$broadcast('updateEdit7', { message: 'refreshing' });
        });
      });
    }

    function manualChangeAct() {
      $uibModalInstance.dismiss('cancel');
      $confirm({ text: 'Enviar?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(function () {
        var params = {};
        params.type = 4;
        params.deviceId = vm.program.pk.deviceId;
        params.action = 5;
        params.parameter1 = Number(vm.program.pk.id) - 1;
        params.parameter2 = vm.xActivations;
        params.parameter3 = vm.xFreqTime;
        params.parameter4 = vm.xFreqTime >> 8;
        manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
        var modalInstance = manualFactory.showLoadingBar();
        modalInstance.result.then((result) => {
          $rootScope.$broadcast('updateEdit7', { message: 'refreshing' });
        });
      });
    }
  }
})();
