import moment from 'moment/moment';
import angular from 'angular';
import modalSuspendActionManual from '../../programs/suspend-modal.html';
import modalFreqDaysActionManual from '../../programs/freq_days.html';
import { UNITS } from '../../utils/units.enum';
import { getContainer } from '@vegga/front-store';
import { Subject, switchMap, takeUntil } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    //TODO - check moment
    .constant('moment', moment)

    .controller('programsDetailControllerA25', programsDetailController)
    .controller('ModalInstanceSuspendCtrl', ModalInstanceSuspendCtrl);

  programsDetailController.$inject = [
    '$log',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'progFactory',
    'unitFactory',
    'UserData',
    'registerFactory',
    'resFactory',
    'NgTableParams',
    '$filter',
    'fertilizerFactory',
    'configFactory',
    'manualFactory',
    '$confirm',
    '$uibModal',
    '$rootScope',
  ];

  function programsDetailController(
    $log,
    $scope,
    $state,
    Restangular,
    $mdDialog,
    progFactory,
    unitFactory,
    UserData,
    registerFactory,
    resFactory,
    NgTableParams,
    $filter,
    fertilizerFactory,
    configFactory,
    manualFactory,
    $confirm,
    $uibModal,
    $rootScope
  ) {
    var vm = this;
    vm.activeList;
    vm.sectorsArea;
    vm.programed;
    vm.getSubprogramStartTime;

    vm.getSubprogramStartTime = getSubprogramStartTime;
    vm.getSubprogramStartTimeNoIrrig = getSubStartTimeNoIrrig;
    vm.getTimeFromMins = progFactory.getTimeFromMins;
    vm.parseTimeFromMin = getTimeFromMin;
    vm.toDate = toDate;
    vm.toHour = toHour;
    vm.formatDate = formatDate;
    vm.selectedOp = '1';
    vm.getValueInUnit = getValueInUnit;
    vm.getCurrentFertilizer = getCurrentFertilizer;
    vm.formatFertilizerValue = formatFertilizerValue;
    vm.manualStart = manualStart;
    vm.manualStop = manualStop;
    vm.manualOutOf = manualOutOf;
    vm.manualRunOf = manualRunOf;
    vm.manualSuspend = manualSuspend;
    vm.manualFreqDays = manualFreqDays;
    vm.goToSector = goToSector;

    vm.viewChangeClicked = viewChangeClicked;
    vm.viewDate = moment().format();
    vm.programsFacade = getContainer().resolve('programsFacade');
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.UNITS = UNITS;
    var sectorsArray = [];
    vm.destroy$ = new Subject();

    activate();

    function activate() {
      vm.currentState = $state.includes;
      initSubscriptions();
    }

    function initSubscriptions() {
      const layoutFilter = document.querySelector('vegga-layout-filter');

      vm.devicesFacade.legacyUnitResponse.value$
        .pipe(
          switchMap((currentUnit) => {
            if (!currentUnit) {
              $state.go('units');
              return throwError(() => of(null));
            }

            vm.currentUnit = currentUnit;
            return vm.programsFacade.currentProgram$;
          }),
          takeUntil(vm.destroy$)
        )
        .subscribe((prog) => {
          vm.program = progFactory.formatProgramView(prog);
          vm.activeSubList = progFactory.filterSubPrograms(vm.program);

          if (vm.currentUnit.type === vm.UNITS.A_BIT) {
            var params = {};
            configFactory.get(vm.currentUnit.id, params).then((result) => {
              vm.config = result;
            });
          }

          if (vm.currentUnit.type === vm.UNITS.A_2500 && vm.program.unit == 2) {
            if (vm.program.sector1 !== null && vm.program.sector1 !== 0) {
              sectorsArray.push(vm.program.sector1);
            }
            if (vm.program.sector2 !== null && vm.program.sector2 !== 0) {
              sectorsArray.push(vm.program.sector2);
            }
            if (vm.program.sector3 !== null && vm.program.sector3 !== 0) {
              sectorsArray.push(vm.program.sector3);
            }
            if (vm.program.sector4 !== null && vm.program.sector4 !== 0) {
              sectorsArray.push(vm.program.sector4);
            }

            var params_sector = {
              sectors: sectorsArray,
            };
            progFactory.getSectorsArea(vm.currentUnit.id, params_sector).then((result) => {
              vm.sectorsArea = result;
              if (vm.sectorsArea > 0 && vm.sectorsArea !== null && vm.sectorsArea !== undefined) {
                vm.programed = (vm.program.value * (vm.sectorsArea / 10000)).toFixed(2);
              }
            });
          }

          vm.fertilizers = [];
          loadRegisterData();
          loadFertilizerParameters();

          loadConditioners();
        });

      vm.programsFacade.isLoading$.pipe(takeUntil(vm.destroy$)).subscribe((isLoading) => {
        if (!isLoading) {
          vm.showSkeleton = isLoading;
        }
      });
    }

    function toDate(date) {
      return moment(date, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY');
    }

    function toHour(date) {
      return moment(date, 'DD-MM-YYYY HH:mm').format('HH:mm');
    }

    function goToSector(id) {
      $state.go('sectors', { unit: vm.currentUnit, load: id });
    }

    function getSubprogramStartTime(index) {
      var subprogram = vm.activeSubList[index];
      if (subprogram !== undefined && (subprogram.unit === 0 || subprogram.unit === 1)) {
        var total = 0;
        if (subprogram.unit === 0) {
          if (index === Number(vm.program.xSubprogramCourse) + 1) {
            total = vm.program.xValue;
          } else {
            total = subprogram.value;
          }
        }
        if (subprogram.unit === 1) {
          if (index === Number(vm.program.xSubprogramCourse) + 1) {
            total = vm.program.xValue / 60;
          } else {
            total = subprogram.value / 60;
          }
        }

        var current;
        if (vm.currentUnit.ram && vm.currentUnit.ram.date) {
          current = moment(vm.currentUnit.ram.date, 'DD-MM-YYYY HH:mm').format('HH:mm');
        } else {
          current = moment().format('HH:mm');
        }
        var parts = current.split(':');
        var hoursToMin = (parseInt(parts[0]) * 60) | 0;
        var temp = hoursToMin + parseInt(parts[1]) + total;
        if (temp > 1440) {
          temp = temp - 1440;
        }
        var irrigationHour = progFactory.getTimeFromMins(temp);
        return irrigationHour.format('HH:mm');
      } else {
        subprogram = _.last(vm.activeSubList);
        if (subprogram.unit === 0 || subprogram.unit === 1) {
          var total = 0;
          if (subprogram.unit === 0) {
            total = subprogram.value;
          }
          if (subprogram.unit === 1) {
            total = subprogram.value / 60;
          }
          var current;
          if (vm.currentUnit.ram && vm.currentUnit.ram.date) {
            current = moment(vm.currentUnit.ram.date, 'DD-MM-YYYY HH:mm').format('HH:mm');
          } else {
            current = moment().format('HH:mm');
          }
          var parts = current.split(':');
          var hoursToMin = (parseInt(parts[0]) * 60) | 0;
          var temp = hoursToMin + parseInt(parts[1]) + total;
          if (temp > 1440) {
            temp = temp - 1440;
          }
          var irrigationHour = progFactory.getTimeFromMins(temp);
          return irrigationHour.format('HH:mm');
        } else {
          return '-';
        }
      }
    }

    function getSubStartTimeNoIrrig(index) {
      if (index === 0 || vm.program.sequential) {
        return vm.program.irrigationHour;
      } else {
        var total = 0;
        var parts = vm.program.irrigationHour.split(':');
        for (var i = 0; i < index; i++) {
          total = total + vm.activeSubList[i].totalTime;
        }
        var hoursToMin = (parseInt(parts[0]) * 60) | 0;
        var temp = hoursToMin + parseInt(parts[1]) + total;
        if (temp > 1440) {
          temp = temp - 1440;
        }
        var irrigationHour = progFactory.getTimeFromMins(temp);
        return irrigationHour.format('HH:mm');
      }
    }

    function getStartTime() {
      var total = 0;
      var parts = vm.program.irrigationHour.split(':');
      for (var i = 0; i < index; i++) {
        total = total + vm.activeSubList[i].totalTime;
      }
      var hoursToMin = (parseInt(parts[0]) * 60) | 0;
      var temp = hoursToMin + parseInt(parts[1]) + total;
      if (temp > 1440) {
        temp = temp - 1440;
      }
      var irrigationHour = progFactory.getTimeFromMins(temp);
      return new Date(irrigationHour._d);
    }

    function getTimeFromMin(min) {
      if (_.isNumber(min)) {
        return progFactory.getTimeFromMins(min).format('HH:mm');
      }
      return min;
    }
    function viewChangeClicked(nextView) {
      if (nextView === 'year' || nextView === 'day' || nextView === 'week') {
        return false;
      }
    }

    function successRegister(data) {
      var register = data.plain();

      if (register.content !== undefined && register.content.length > 0) {
        registerFactory.parseRegister(vm.currentUnit.type, register.content);
        // TODO - code refactored
        //   switch(vm.currentUnit.type){
        //       case 3:
        //           registerFactory.parseRegister( "3" , register.content );
        //         break;
        //       case 4:
        //           registerFactory.parseRegister( "4" , register.content );
        //         break;
        //   }

        var response = register.content;

        vm.tableParams.total(register.totalElements);

        return response;
      }
    }

    function loadConditioners() {
      unitFactory.conditioners(vm.currentUnit.id).then((response) => {
        vm.currentUnit.conditioners = response.plain();
        var conditioners = [];
        if (vm.program.condId1 !== 0) {
          var cond = vm.currentUnit.conditioners[vm.program.condId1 - 1];
          if (cond !== undefined) {
            cond.id = vm.program.condId1;
            conditioners.push(cond);
          }
        }
        if (vm.program.condId2 !== 0) {
          var cond = vm.currentUnit.conditioners[vm.program.condId2 - 1];
          if (cond !== undefined) {
            cond.id = vm.program.condId2;
            conditioners.push(cond);
          }
        }
        if (vm.program.condId3 !== 0) {
          var cond = vm.currentUnit.conditioners[vm.program.condId3 - 1];
          if (cond !== undefined) {
            cond.id = vm.program.condId3;
            conditioners.push(cond);
          }
        }
        if (vm.program.condId4 !== 0) {
          var cond = vm.currentUnit.conditioners[vm.program.condId4 - 1];
          if (cond !== undefined) {
            cond.id = vm.program.condId4;
            conditioners.push(cond);
          }
        }
        if (vm.program.condId5 !== 0) {
          var cond = vm.currentUnit.conditioners[vm.program.condId5 - 1];
          if (cond !== undefined) {
            cond.id = vm.program.condId5;
            conditioners.push(cond);
          }
        }
        vm.conditionerList = conditioners;
      });
    }

    function loadRegisterData() {
      vm.tableParams = new NgTableParams(
        {
          page: 1,
          count: 15,
          filter: { type: '' },
        },
        {
          counts: [],
          getData: function (params) {
            var pageNumber = 1;
            if (params._params) {
              pageNumber = params._params.page;
            }

            if (params._params.filter.type !== '') {
              var type = params._params.filter.type;
              return unitFactory
                .programRegisters(vm.program.pk.deviceId, vm.program.pk.id, type, pageNumber)
                .then(successRegister);
            } else {
              return unitFactory
                .programRegisters(vm.program.pk.deviceId, vm.program.pk.id, null, pageNumber)
                .then(successRegister);
            }
          },
        }
      );
    }

    function getValueInUnit(input) {
      var unit = vm.program.unit;
      const decimal = vm.currentUnit.installer.decimalsIrrigation;

      if (unit === 0 || unit === 16) {
        input = input / 60;
      }
      switch (unit) {
        case 0:
          return $filter('parsetime')(input * 60);
          break;
        case 1:
          return (input / 1000).toFixed(decimal >= 0 ? decimal : 2) + 'm3';
          break;
        case 3:
          var mins = input;
          return $filter('parsetime', true)(mins);
          break;
        case 4:
          return input + 'l';
          break;
        case 2:
          //if(prog == 1) input = vm.programed;
          return (input / 1000).toFixed(decimal >= 0 ? decimal : 2) + 'm3';
          break;
      }
    }

    function formatDate(date) {
      return moment(date).format('MMMM-YYYY');
    }

    function formatFertilizerValue(value) {
      var general = vm.currentUnit.fertilizer;

      switch (general.fertUnits) {
        case 0: //A25_UNIDADES_FERT_HM:
          // Passem a minuts,, si el resto es diferent de 0 => sumem 1
          if (value % 60 != 0) {
            value = value / 60 + 1;
          } else {
            value = value / 60;
          }
          value = $filter('parsetime')(value * 60, true);
          break;
        case 1: //A25_UNIDADES_FERT_L:
        case 2: //A25_UNIDADES_FERT_LHA:
          value = value / 100 + ' l'; //cl to l
          /*                switch(general.fertDecimals)
                {
                    case 0:
                        value = value + ' l';    
                        break;
                    case 1:
                        value = value/10 + ' l';    
                        break;
                    case 2:        
                        value = value/100 + ' l';    
                        break;
                }*/
          break;

        case 3: //A25_UNIDADES_FERT_MS:
          var mins = value * 60 * 60;
          value = $filter('parsetime')(mins);
          break;
      }
      return value;
    }

    function getCurrentFertilizer(index) {
      return vm.fertilizers[index];
    }

    function loadFertilizerParameters() {
      fertilizerFactory.all(vm.currentUnit.id).then((fert) => {
        vm.parameters = fert;
        _.times(4, (x) => {
          var currentProgram = vm.parameters.fertilizers[x].xProgramN;
          if (currentProgram === Number(vm.program.pk.id)) {
            var key = 'fertilizer' + (x + 1);
            var total = vm.program[key];
            /*                     if(vm.parameters.fertilizers[x].xState === 1){
                        vm.fertilizers[x] = (total - vm.parameters.fertilizers[x].xVolume);    
                    }else{
                         vm.fertilizers[x] = total;
                    } */
            vm.fertilizers[x] = total - vm.parameters.fertilizers[x].xVolume;
          } else {
            vm.fertilizers[x] = -1;
          }
        });
      });
    }

    function manualStart() {
      $confirm({ text: 'Enviar inicio manual?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(function () {
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.program.pk.deviceId;
        params.action = 4;
        params.parameter1 = Number(vm.program.pk.id) - 1;
        manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
        var modalInstance = manualFactory.showLoadingBar();
        modalInstance.result.then((result) => {
          $state.go('a25programs', { unit: $state.params.unit }, { reload: 'a25programs' });
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function manualStop() {
      $confirm({ text: 'Quiere enviar un paro de programa?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(
        function () {
          var params = {};
          params.type = vm.currentUnit.type;
          params.deviceId = vm.program.pk.deviceId;
          params.action = 5;
          params.parameter1 = Number(vm.program.pk.id) - 1;
          manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
          var modalInstance = manualFactory.showLoadingBar();
          modalInstance.result.then((result) => {
            $state.go('a25programs', { unit: $state.params.unit }, { reload: 'a25programs' });
            $scope.$emit('reload', { message: 'reload' });
          });
        }
      );
    }

    function manualOutOf() {
      $confirm({ text: 'Enviar fuera de servicio?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(
        function () {
          var params = {};
          params.type = vm.currentUnit.type;
          params.deviceId = vm.program.pk.deviceId;
          params.action = 0;
          params.parameter1 = Number(vm.program.pk.id) - 1;
          params.parameter2 = 1;
          manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
          var modalInstance = manualFactory.showLoadingBar();
          modalInstance.result.then((result) => {
            $state.go('a25programs', { unit: $state.params.unit }, { reload: 'a25programs' });
            $scope.$emit('reload', { message: 'reload' });
          });
        }
      );
    }

    function manualRunOf() {
      $confirm({ text: 'Enviar orden de activacion?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(
        function () {
          var params = {};
          params.deviceId = vm.program.pk.deviceId;
          params.type = vm.currentUnit.type;
          params.action = 0;
          params.parameter1 = Number(vm.program.pk.id) - 1;
          params.parameter2 = 0;
          manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
          var modalInstance = manualFactory.showLoadingBar();
          modalInstance.result.then((result) => {
            $state.go('a25programs', { unit: $state.params.unit }, { reload: 'a25programs' });
            $scope.$emit('reload', { message: 'reload' });
          });
        }
      );
    }

    function manualSuspend() {
      var modalInstance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        //   templateUrl: 'components/programs/suspend-modal.html',
        template: modalSuspendActionManual,
        controller: 'ModalInstanceSuspendCtrl',
        controllerAs: 'vm',
      });

      modalInstance.result.then(
        function (selectedItem) {
          var modalInstance = manualFactory.showLoadingBar();

          var params = {};
          params.type = vm.currentUnit.type;
          params.deviceId = vm.program.pk.deviceId;
          params.action = 1;
          params.parameter1 = Number(vm.program.pk.id) - 1;
          params.parameter2 = selectedItem;
          params.parameter3 = selectedItem >> 8;
          manualFactory.genericManualAction(params.deviceId, params).then((result) => {});
          modalInstance.result.then((result) => {
            $rootScope.$broadcast('reload', { message: 'refreshing' });
          });
        },
        function () {
          $log.info('Modal dismissed at: ' + new Date());
        }
      );
    }

    function manualFreqDays() {
      var modalInstance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        template: modalFreqDaysActionManual,
        controller: 'ModalInstanceCtrl',
        controllerAs: 'vm',
        resolve: {
          program: function () {
            return vm.program;
          },
        },
      });

      modalInstance.result.then(
        function (selectedItem) {
          var params = {};
          params.type = vm.currentUnit.type;
          params.deviceId = vm.program.pk.deviceId;
          params.action = 2;
          params.parameter1 = vm.program.pk.id - 1;
          params.parameter2 = selectedItem;
          var modalInstance = manualFactory.showLoadingBar();
          manualFactory.genericManualAction(params.deviceId, params).then((response) => {});

          modalInstance.result.then((result) => {
            $rootScope.$broadcast('reload', { message: 'refreshing' });
          });
        },
        function () {
          $log.info('Modal dismissed at: ' + new Date());
        }
      );
    }

    $scope.$watch(
      function () {
        return moment(vm.viewDate).format('MM-YYYY');
      },
      function (month) {
        vm.events = progFactory.getEventTable(vm.program, month);
      }
    );

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }

  ModalInstanceSuspendCtrl.$inject = ['$uibModalInstance'];

  function ModalInstanceSuspendCtrl($uibModalInstance) {
    var vm = this;
    vm.selectedHours = 0;

    vm.ok = function () {
      $uibModalInstance.close(vm.selectedHours);
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }
})();
