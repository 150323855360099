import { UNITS } from '../utils/units.enum';
import { getContainer } from '@vegga/front-store';
import { filter, from, switchMap, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs';
import { A2500_DEFAULT_CONFIG } from '../utils/device-config';
import { METERFORMATS45 } from '../utils/meters-formats45.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('sectorsController', sectorsController);

  sectorsController.$inject = ['$state', 'sectorFactory', 'sensorsFactory', '$filter', '$confirm', '$scope'];

  function sectorsController($state, sectorFactory, sensorsFactory, $filter, $confirm, $scope) {
    var vm = this;
    vm.newSector;
    vm.cancel;
    vm.save;
    vm.changeSectorState;
    vm.meters;
    vm.getFilterInUse;

    vm.rawSectors;
    vm.sectorData;
    vm.totalSectors;
    vm.searchBySectorName = '';
    vm.searchBySectorHeader = [];
    vm.searchBySectorState = [];
    vm.meterFormats = METERFORMATS45;
    vm.destroy$ = new Subject();
    vm.UNITS = UNITS;

    vm.cropsFacade = getContainer().resolve('cropsFacade');
    vm.sectorsFacade = getContainer().resolve('sectorsFacade');
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    generateA4500ColDef();
    //TODO FIX i18n
    vm.gridColumnsDef = [
      {
        headerName: $filter('translate')('history.sector'),
        field: 'id',
        width: 100,
        sorting: true,
        sortingDir: 'asc',
      },
      {
        headerName: $filter('translate')('programs.detail.name'),
        field: 'name',
        filterParams: (cellValue) => {
          if (!vm.searchBySectorName) {
            return true;
          }
          if (cellValue) {
            return cellValue.toLowerCase().includes(vm.searchBySectorName.toLowerCase());
          }
          return false;
        },
      },
      {
        headerName: $filter('translate')('sectors.c23'),
        field: 'area',
        cellTemplate: (areaItem) => {
          if (typeof areaItem.area === 'number') {
            return `<vegga-text formatted suffix-unit="m2">${areaItem.area}</vegga-text>`;
          } else {
            return '';
          }
        },
      },
      { headerName: $filter('translate')('sectors.c12'), field: 'cropText' },
      {
        headerName: $filter('translate')('history.prog'),
        field: 'programList',
        cellTemplate: (sectorItem) => renderProgramTag(sectorItem.programs || sectorItem.programList).join(''),
      },
      {
        headerName: $filter('translate')('sensors.preFlow'),
        field: 'plannedFlow',
        cellTemplate: (sector) => {
          const tmpValue = sector.plannedFlow / 100;

          const flowUnit =
            vm.currentUnit.type === vm.UNITS.A_2500
              ? A2500_DEFAULT_CONFIG.measurementUnits.flow.find(
                  (format) => format.deviceMeasurementUnitKey === sector.flowFormat
                )?.measurementUnit.symbol ?? 'm3/h'
              : vm.currentUnit.type === vm.UNITS.A_4000
              ? 'm3/h'
              : prepareFlowUnits(vm.rawSectors);

          return `<vegga-text formatted="true" suffix-unit="${flowUnit}">${tmpValue}</vegga-text>`;
        },
      },
      {
        headerName: $filter('translate')('fert.state'),
        field: 'status',
        cellTemplate: (sectorItem) => renderStatusTag(sectorItem),
      },
    ];

    vm.stateFilterList = [
      { label: $filter('translate')('programs.irrigating'), value: 'irrigating' },
      { label: $filter('translate')('sensors.error'), value: -1 },
      { label: $filter('translate')('sectors.manual_0'), value: 0 },
      { label: $filter('translate')('sectors.manual_1'), value: 1 },
      { label: $filter('translate')('sectors.manual_2'), value: 2 },
      { label: $filter('translate')('sectors.manual_3'), value: 3 },
      { label: $filter('translate')('sectors.errorflow_2'), value: 'errorflow_2' },
      { label: $filter('translate')('sectors.errorflow_3'), value: 'errorflow_3' },
      { label: $filter('translate')('sectors.errorflow_5'), value: 'errorflow_5' },
      { label: $filter('translate')('sectors.errorflow_6'), value: 'errorflow_6' },
      { label: $filter('translate')('sectors.errorflow_7'), value: 'errorflow_7' },
      { label: $filter('translate')('sectors.errorflow_auxiliar_meter_9'), value: 'auxiliar_meter_9' },
      { label: $filter('translate')('sectors.errorflow_auxiliar_meter_10'), value: 'auxiliar_meter_10' },
      { label: $filter('translate')('sectors.errorflow_auxiliar_meter_13'), value: 'auxiliar_meter_13' },
    ];

    vm.headerFilterValues = [
      { value: 0, label: $filter('translate')('programs.nothing') },
      { value: 1, label: `${$filter('translate')('sectors.header')} 1` },
      { value: 2, label: `${$filter('translate')('sectors.header')} 2` },
      { value: 3, label: `${$filter('translate')('sectors.header')} 3` },
      { value: 4, label: `${$filter('translate')('sectors.header')} 4` },
      { value: 255, label: $filter('translate')('programs.edit.headerError') },
    ];

    vm.newSector;
    vm.dataGridRowClick;

    var a25FlowUnits = {
      0: 'm3/h',
      1: 'L/h',
      2: 'L/s',
    };
    let grid;

    activate();
    function activate() {
      vm.currentState = $state.includes;
      vm.cropsFacade.getCrops();
      grid = document.querySelector('vegga-data-grid');
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }

        vm.currentUnit = currentUnit;
        vm.dataGridRowClick = dataGridRowClick;
        vm.checkIrrigation = checkIrrigation;
        vm.prepareFlowUnits = prepareFlowUnits;
        vm.checkState = checkState;
        vm.cancel = cancel;
        vm.save = save;
        vm.changeSectorState = changeSectorState;
        vm.changeSector = changeSector;
        vm.changingSector = changingSector;
        vm.nextOrPreviousItem = nextOrPreviousItem;
        vm.updateFilter = updateFilter;
        vm.gridFilter = { name: vm.searchBySectorName };
        vm.gridFilter45 = {
          name: vm.searchBySectorName,
          header: vm.searchBySectorHeader,
          sectorState: vm.searchBySectorState,
        };
        vm.getFilterInUse = getFilterInUse;
        loadSectors(vm.currentUnit.id, vm.currentUnit.type, vm.currentUnit.installer);
        if (vm.currentUnit.type !== vm.UNITS.A_2500 && vm.currentUnit.type !== vm.UNITS.A_4000) {
          getMeters();
        }

        vm.showIrrig = false;
      });

      vm.sectorsFacade.isLoading$
        .pipe(
          filter((isLoading) => !isLoading),
          takeUntil(vm.destroy$),
        )
        .subscribe((isLoading) => {
          const layoutFilter = document.querySelector('#deviceLayoutHeader');
          layoutFilter.showSkeleton = true;
          if (!isLoading) {
            grid.showFullSkeleton = isLoading;
            grid.showRowSkeleton = isLoading;
            layoutFilter.showSkeleton = isLoading;
            vm.showSkeleton = isLoading;
          }
        });
    }

    function getFormatMeterAux45() {
      sensorsFactory.getMetersSector(vm.currentUnit.id).then(function (data) {
        vm.metersSectors = data.plain();
        vm.sectorData = vm.sectorData.map((sector) => {
          if (!vm.metersSectors[sector.meterFormat - 1]) return sector;
          let meterFormatValue = vm.metersSectors[sector.meterFormat - 1]?.formatMeter;

          return { ...sector, formatMeterAux45: sectorFactory.sectorFlowFormat45(meterFormatValue, sector.xFlow) };
        });
        grid.columnsDef = vm.currentUnit.type !== vm.UNITS.A_4500 ? vm.gridColumnsDef : vm.gridColumnsDefA45;
        grid.data = vm.sectorData;
      });
    }

    function generateA4500ColDef() {
      vm.gridColumnsDefA45 = [
        {
          headerName: $filter('translate')('history.sector'),
          field: 'id',
          width: 100,
          cellTemplate: (sec) => `<vegga-text>S${sec.id}</vegga-text>`,
        },
        {
          headerName: $filter('translate')('programs.detail.name'),
          field: 'name',
          cellTemplate: (sec) => `<vegga-text class='semi-bold'>${sec.name}</vegga-text>`,
          filterParams: (cellValue) =>
            vm.searchBySectorName.length ? cellValue.toLowerCase().includes(vm.searchBySectorName.toLowerCase()) : true,
        },
        {
          headerName: $filter('translate')('sectors.header'),
          field: 'header',
          width: 100,
          filterParams: (cellValue) =>
            vm.searchBySectorHeader.length ? vm.searchBySectorHeader.includes(cellValue) : true,
        },
        {
          headerName: $filter('translate')('sectors.c23'),
          field: 'area',
          cellTemplate: (sec) => `<vegga-text>${sec.area} m2</vegga-text>`,
        },
        { headerName: $filter('translate')('sectors.c12'), field: 'cropText' },
        { headerName: 'Prog/Subprog', field: 'xNProgram', cellTemplate: (sec) => getProgSubprogCellTemplate(sec) },
        {
          headerName: 'Riego Pend/Prog',
          field: 'irrigProgVisual',
          cellTemplate: (sec) => getIrrigProgCellTemplate(sec),
        },
        {
          headerName: $filter('translate')('sectors.c8'),
          field: 'plannedFlow',
          cellTemplate: (sec) => `<vegga-text class="md">${sec.plannedFlow / 100} m3/h</vegga-text>`,
        },
        {
          headerName: $filter('translate')('sectors.actual_flow'),
          field: 'irrigationMeter',
          cellTemplate: (sec) => {
            if (sec.detectorOrMeter && sec.flowInput > 0 && sec.formatMeterAux45 && sec.xStatus === 1) {
              return `<vegga-text class="md">${sec.formatMeterAux45}</vegga-text>`;
            } else {
              return `<vegga-text class="md">-</vegga-text>`;
            }
          },
        },
        {
          headerName: $filter('translate')('fert.state'),
          field: 'sectorState',
          cellTemplate: (sec) => getStateCellTemplate(sec),
          filterParams: (cellValue) => {
            if (!vm.searchBySectorState.length) return true;
            if (vm.searchBySectorState.includes('irrigating') && cellValue.xStatus === 1) return true;
            if (cellValue.errFlowInput && vm.searchBySectorState.includes(cellValue.errFlowInput)) return true;
            if (vm.searchBySectorState.includes(cellValue.state)) return true;
            return false;
          },
        },
      ];
    }

    function getProgSubprogCellTemplate(sec) {
      if (!sec.xNProgram || !sec.subprog) return '-';

      return `<div style="display:flex;">
                <vegga-tag
                  style="margin-bottom: 8px; margin-right: 4px"
                  class="md"
                  enable-remove=${false}
                  tag="${sec.xNProgram}"></vegga-tag>

                 <vegga-text class="md"> / </vegga-text>

                <vegga-tag
                  style="margin-bottom: 8px; margin-right: 4px"
                  class="md"
                  enable-remove=${false}
                  tag=" ${sec.subprog}"></vegga-tag>
              </div>
      `;
    }

    function getIrrigProgCellTemplate(sec) {
      return sec.irrigPendVisual !== undefined && sec.irrigProgVisual
        ? `<vegga-text tag="span" class="md semi-bold">
          ${sec.irrigPendVisual}
          <span>/</span> ${sec.irrigProgVisual}
        </vegga-text>`
        : `<vegga-text>-</vegga-text>`;
    }

    function getStateCellTemplate(sec) {
      return `
      <div style="display:'flex'; flex-direction: 'column';">
        ${
          sec.xStatus === 1
            ? `<vegga-badge class="sm blue-water-400">${$filter('translate')('sectors.c2')}</vegga-badge>`
            : ''
        }
        ${
          sec.state === 0 || sec.state === 1
            ? `<vegga-badge class="sm violet-400">${$filter('translate')('sectors.manual_' + sec.state)}</vegga-badge>`
            : ''
        }
        ${
          sec.state === 2
            ? `<vegga-badge class="sm black outline">${$filter('translate')(
                'sectors.manual_' + sec.state
              )}</vegga-badge>`
            : ''
        }
        ${
          sec.state === 3
            ? `<vegga-badge class="sm grey-400">${$filter('translate')('sectors.manual_' + sec.state)}</vegga-badge>`
            : ''
        }
        ${
          sec.errFlowInput
            ? `<vegga-badge class="${sec.errFlowInput == 7 ? 'red-teaberry-400' : ''}">${$filter('translate')(
                'sectors.errorflow_' + sec.errFlowInput
              )}</vegga-badge>`
            : ''
        }
      </div>
      `;
    }

    //Funció adaptada per mirar l'estat de cada sector
    function checkState(sector) {
      switch (vm.currentUnit.type) {
        case UNITS.A_4000:
        case UNITS.A_2500:
        case UNITS.A_BIT:
          if (sector.xStatus !== null && sector.xStatus !== 0 && sector.xStatus !== 4) {
            if (sector.xFlowInput === 2 || sector.xFlowInput === 3) {
              return true;
            } else return false;
          } else if (sector.xStatus === null || sector.xStatus === 0 || sector.xStatus === 4) {
            if (sector.xFlowInput === 5 || sector.xFlowInput === 6) {
              return true;
            }
            return false;
          }
          break;
        case UNITS.A_7000:
          if (sector.xStatus !== 0) {
            sector.state = 1;
          } else {
            sector.state = 0;
          }

          break;
        case UNITS.A_5500:
          if (sector.xStatus !== 0 && sector.xStatus !== 3 && sector.xStatus !== 5 && sector.xStatus !== 6) {
            sector.state = 1;
          } else {
            sector.state = 0;
          }
          break;
      }
    }

    function dataGridRowClick(e) {
      vm.sector = vm.rawSectors.find((sector) => sector.pk.id === e.detail.id);

      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();

      changeState({ sector: vm.sector, id: vm.sector.pk.id, unit: vm.currentUnit }, $state.params.config);
      //changeState(e.detail.id, $state.params.config);
    }

    function changeSector(e) {
      const sectorId = e.detail.value;
      if (sectorId) {
        const sector = vm.rawSectors.find((sector) => sector.pk.id === sectorId);
        vm.sector = sector;
        changeState({ sector: sector, id: sectorId, unit: vm.currentUnit }, $state.params.config);
      }
    }

    function changingSector($event) {
      if (vm.formSector && vm.formSector.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formSector.$setPristine(); //Actualitzem estat del formulari a inicial
          this.nextOrPreviousItem($event.detail.action, $event);
        });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeSector($event);
          break;
      }
    }

    //Funcio per mirar l'estat del sector
    function checkIrrigation(sector) {
      sector.irrigation = false;
      if (sector.xStatus !== null) {
        switch (vm.currentUnit.type) {
          case UNITS.A_4000: //A4000
            if (sector.xStatus !== 4 && sector.xStatus !== 0) {
              // 4 = Stop In active (Paro definitiu de sector)
              sector.irrigation = true;
            }
            break;
          case UNITS.A_2500: //A2500
          case UNITS.A_BIT: //BIT
            if (sector.xStatus !== 0 && sector.xStatus !== 2 && sector.xStatus !== 5) {
              sector.irrigation = true;
            }
            break;
          case UNITS.A_7000: //A7000
            if (sector.xStatus !== 0) {
              sector.irrigation = true;
            }
            break;
          case UNITS.A_5500: //A5500
            if (sector.xStatus !== 0 && sector.xStatus !== 3 && sector.xStatus !== 5 && sector.xStatus !== 6) {
              sector.irrigation = true;
            }
            break;
          case UNITS.A_4500: //A4500
            if (sector.xStatus == 1) {
              sector.irrigation = true;
            }
            break;
        }
      }
      return sector.irrigation;
    }

    //Funcio necessaria per agafar els formats dels sensors contadors
    function getMeters() {
      sensorsFactory.meters(vm.currentUnit.id).then((result) => {
        vm.meters = result.plain();
        generateA4500ColDef();
      });
    }

    //Determina el format del caudal previst per cada sector
    function prepareFlowUnits(sector) {
      var flowFormat = 0;
      if (sector.irrigationMeter !== 0 && vm.meters && vm.meters.length > 0) {
        const meter = vm.meters.find((meter) => Number(meter.pk.id) === sector.irrigationMeter);
        if (meter && meter.flowFormat) {
          flowFormat = meter.flowFormat;
        }
      }
      flowFormat = a25FlowUnits[flowFormat];
      return flowFormat;
    }

    function loadSectors(id, type, installer) {
      vm.sectorsFacade.clearSectorsResponse();

      switch (vm.currentUnit.type) {
        case UNITS.A_4000:
          vm.sectorsFacade.getA4000Sectors(vm.currentUnit.id);
          return loadSectorsA4000();
        case UNITS.A_2500:
          vm.sectorsFacade.getA2500Sectors(vm.currentUnit.id);
          return loadSectorsA2500();
        case UNITS.A_BIT:
          vm.sectorsFacade.getABITSectors(vm.currentUnit.id, true);
          return loadSectorsABIT(installer);
        case UNITS.A_4500:
          vm.sectorsFacade.getA4500Sectors(vm.currentUnit.id);
          return loadSectorsA4500(installer);
      }

      return from(sectorFactory.sector(id, type, installer))
        .pipe(
          switchMap((sectors) => {
            vm.rawSectors = sectors;
            return vm.cropsFacade.cropsResponse.value$;
          })
        )
        .subscribe((crops) => {
          vm.sectorData = parseSectorsData(vm.rawSectors, crops);

          prepareFlowData();
        });
    }

    function parseSectorsData(sectors, crops) {
      return sectors.map((sector) => {
        const crop = crops.find((crop) => sector.crop === crop.id);
        return {
          ...sector,
          id: sector.pk.id,
          name: sector.name,
          cropText: crop ? crop.name : '-',
          programs: sector.progs,
          status: [],
          sectorState: {
            xStatus: sector.xStatus,
            state: sector.state,
            errFlowInput: sector.errFlowInput,
          },
        };
      });
    }

    function loadSectorsA4000() {
      vm.sectorsFacade.sectors$
        .pipe(
         takeUntil(vm.destroy$),
          switchMap((sectors) => {
            vm.rawSectors = sectors;
            return vm.cropsFacade.cropsResponse.value$;
          })
        )
        .subscribe((crops) => {
          vm.sectorData = parseSectorsData(vm.rawSectors, crops);
          grid.data = vm.sectorData;
          grid.columnsDef = vm.currentUnit.type !== vm.UNITS.A_4500 ? vm.gridColumnsDef : vm.gridColumnsDefA45;
          prepareFlowData();
        });
    }

    function loadSectorsA2500() {
      vm.sectorsFacade.sectors$
        .pipe(
         takeUntil(vm.destroy$),
          switchMap((sectors) => {
            // Since in agronic a lot of conditions is !== null and backend does not send null values, we parse those necessary values
            vm.rawSectors = sectors.map((sector) => parseNullValues(sector));
            return vm.cropsFacade.cropsResponse.value$;
          })
        )
        .subscribe((crops) => {
          vm.sectorData = parseSectorsData(vm.rawSectors, crops);
          grid.data = vm.sectorData;
          grid.columnsDef = vm.currentUnit.type !== vm.UNITS.A_4500 ? vm.gridColumnsDef : vm.gridColumnsDefA45;
          prepareFlowData();
        });
    }

    function loadSectorsABIT() {
      vm.sectorsFacade.sectors$
        .pipe(
         takeUntil(vm.destroy$),
          switchMap((sectors) => {
            // Since in agronic a lot of conditions is !== null and backend does not send null values, we parse those necessary values
            vm.rawSectors = sectors.map((sector) => parseNullValues(sector));
            return vm.cropsFacade.cropsResponse.value$;
          })
        )
        .subscribe((crops) => {
          vm.sectorData = parseSectorsData(vm.rawSectors, crops);
          grid.data = vm.sectorData;
          grid.columnsDef = vm.gridColumnsDef;
          prepareFlowData();
        });
    }

    function loadSectorsA4500(installer) {
      vm.sectorsFacade.sectors$
        .pipe(
         takeUntil(vm.destroy$),
          switchMap((sectors) => {
            const conditionLimited = vm.currentUnit.type === UNITS.A_4500 && !vm.currentUnit.inoptions.optionPlus;
            vm.rawSectors = conditionLimited ? sectors.slice(0, 99) : sectors;
            return vm.cropsFacade.cropsResponse.value$;
          })
        )
        .subscribe((crops) => {
          vm.sectorData = parseSectorsData(vm.rawSectors, crops);
          sectorFactory.prepareFlowUnitsA4500(vm.sectorData, installer);
          getFormatMeterAux45();
          prepareFlowData();
          grid.columnsDef = vm.currentUnit.type !== vm.UNITS.A_4500 ? vm.gridColumnsDef : vm.gridColumnsDefA45;
          grid.data = vm.sectorData;
        });
    }

    function parseNullValues(sector) {
      sector.waterDisp = sector.waterDisp || sector.waterDisp === 0 ? sector.waterDisp : null;
      sector.waterDispUser = sector.waterDispUser || sector.waterDispUser === 0 ? sector.waterDispUser : null;
      sector.effectiveRain = sector.effectiveRain || sector.effectiveRain === 0 ? sector.effectiveRain : null;
      sector.effectiveRainUser =
        sector.effectiveRainUser || sector.effectiveRainUser === 0 ? sector.effectiveRainUser : null;
      return sector;
    }

    function renderProgramTag(programs) {
      if (!programs) {
        return [];
      }

      if (vm.currentUnit.type === UNITS.A_4000 || vm.currentUnit.type === UNITS.A_2500) {
        return programs.map((program) => {
          return `<vegga-tag
          class="md"
          enable-remove="false"
          tag="${program}"></vegga-tag>`;
        });
      }
      //per a separar els diferents programes de cada sector
      const parsedPrograms = programs.split(';');

      //render del vegga-tag
      return parsedPrograms.map((program) => {
        return `<vegga-tag
        class="md"
        enable-remove="false"
        tag="${program}"></vegga-tag>`;
      });
    }

    function renderStatusTag(sector) {
      const tags = [];

      if (vm.checkIrrigation(sector)) {
        tags.push(`<vegga-badge class="sm blue-water-400">${$filter('translate')('sectors.c2')}</vegga-badge>`);
      }

      if (!vm.checkIrrigation(sector)) {
        tags.push(`<vegga-badge class="sm grey-400">${$filter('translate')('sectors.not_irrigating')}</vegga-badge>`);
      }

      if (sector.xManual === 1) {
        tags.push(
          `<vegga-badge class="sm violet-400">${$filter('translate')(
            'sectors.manual_' + sector.xStartStop
          )}</vegga-badge>`
        );
      }

      if (vm.checkState(sector)) {
        tags.push(
          `<vegga-badge class="sm red-teaberry-400">${$filter('translate')(
            'sectors.errorflow_' + sector.xFlowInput
          )}</vegga-badge>`
        );
      }

      return tags.join('');
    }

    function updateFilter(param, field) {
      if (vm.currentUnit.type === UNITS.A_4500) {
        vm.gridFilter45 = {
          ...vm.gridFilter45,
          [field]: param,
        };
      } else {
        vm.gridFilter = {
          ...vm.gridFilter,
          [field]: param,
        };
      }
    }

    function prepareFlowData() {
      let filter;
      if (vm.currentUnit.type == UNITS.A_4500) {
        filter = { xStatus: 1 };
      } else {
        filter = { state: 1 };
      }
      let meter_groups = _(_.filter(vm.rawSectors, filter))
        .groupBy('irrigationMeter')
        .map((objs, key) => ({
          irrigationMeter: Number(key),
          total: _.sumBy(objs, 'plannedFlow'),
        }))
        .value();

      _.forEach(vm.rawSectors, (sector) => {
        if (
          (vm.currentUnit.type === UNITS.A_4500 && sector.xStatus === 1) ||
          (vm.currentUnit.type !== UNITS.A_4500 && sector.state === 1)
        ) {
          let meter = _.find(meter_groups, { irrigationMeter: sector.irrigationMeter });
          sector.totalFlow = formatFlowUnit(meter.total);
        }
      });
    }

    function formatFlowUnit(flow) {
      var value = String(flow);
      if (value.length === 2) {
        value = '0.' + value;
      } else if (value.length === 1) {
        value = '0.0' + value;
      } else if (value.length > 2) {
        var first = value.slice(0, value.length - 2);
        var last = value.slice(value.length - 2, value.length);
        value = first + '.' + last;
      }
      return Number(value);
    }

    function changeState(params) {
      vm.active = null;
      const path = $state.current.name.split('.')[1];
      $state.go(`sectors.${path || 'detail'}`, params);
    }

    /**
     * Funció per gestionar els canvis d'estat a a Info i Config.
     * Evaluem estat del formulari per avisar de modificació i validar-ne el canvi.
     */
    function changeSectorState(state, _params) {
      const params = { ..._params, unit: vm.currentUnit };
      if (vm.formSector && vm.formSector.$dirty) {
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formSector.$setPristine(); //Actualitzem estat del formulari a inicial
          $state.go(state, params); //Canviem d'estat
        });
      } else {
        $state.go(state, params);
      }
    }

    /**
     * Funcions que executen el broadcast al child controller de Configuració
     */
    function cancel($event) {
      if (vm.formSector && vm.formSector.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formSector.$setPristine(); //Actualitzem estat del formulari a inicial
          if (typeof $event !== 'undefined') {
            document.querySelector('vegga-overlay').dismiss();
            $state.go('sectors');
          } else {
            $state.go('sectors.detail', { id: vm.sector.pk.id, sector: vm.sector, unit: vm.currentUnit });
          }
        });
      } else {
        typeof $event !== 'undefined'
          ? $state.go('sectors')
          : $state.go('sectors.detail', { sector: vm.sector, id: vm.sector.pk.id, unit: vm.currentUnit });
      }
    }
    function save() {
      if (vm.formSector && vm.formSector.$dirty) {
        $confirm({ text: $filter('translate')('programs.edit.saveq') }).then(() => {
          $scope.$broadcast('formSubmit');
        });
      }
    }

    function getFilterInUse(sector) {
      switch (vm.currentUnit.type) {
        case UNITS.A_4000:
        case UNITS.A_2500:
        case UNITS.A_BIT:
        case UNITS.A_7000:
        case UNITS.A_5500:
          return true;
        case UNITS.A_4500: //A4500
          if (vm.showIrrig) {
            return sector.xStatus == 1;
          } else {
            return true;
          }
      }
    }
    /**
     * Event listener per gestionar l'estat del formulari
     */
    $scope.$on('formUpdated', (e, args) => {
      vm.formSector = args;
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
